import { navigation } from 'app/navigation/navigation';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { environment } from '@env/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/router";
import * as i3 from "../../../@fuse/components/navigation/navigation.service";
const BASE_URL_WEBAPI = environment.webApiBaseUri;
export class AuthenticationService {
    constructor(http, router, authentication, fuseNavigation) {
        this.http = http;
        this.router = router;
        this.authentication = authentication;
        this.fuseNavigation = fuseNavigation;
        this.currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
        this.settingsSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('settings')));
        this.settings = this.settingsSubject.asObservable();
    }
    get currentUserValue() {
        return this.currentUserSubject.value;
    }
    get eFilingSettings() {
        return this.settingsSubject.value;
    }
    login(username, password) {
        return this.http.post(`${BASE_URL_WEBAPI}/users`, { username, password })
            .pipe(map(response => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            this.RegisterCurrentUserSetup(response);
            //redirect an Admin to this page
            if (this.isAdmin('SecurityAdmin')) {
                this.router.navigate(['/user-management']);
            }
            return response;
        }));
    }
    loginFromOc(ocToLascId) {
        return this.http.post(`${BASE_URL_WEBAPI}/users/orange-county-session-mapping`, { value: ocToLascId })
            .pipe(map(response => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            this.RegisterCurrentUserSetup(response);
            return response.isSuccess;
        }));
    }
    loginFromEFilingCentral(token) {
        var result = new Subject();
        this.http.post(`${environment.eFilingCentralApiBaseUri}/users/central-login`, { value: token })
            .subscribe(response => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            this.RegisterCurrentUserSetup(response);
            result.next(true);
        });
        return result.asObservable();
    }
    RegisterCurrentUserSetup(response) {
        localStorage.setItem('currentUser', JSON.stringify(response.data));
        this.currentUserSubject.next(response.data);
        this.currentUserSubject.subscribe(u => u.profileImageURL = "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png");
        this.setupMenu();
    }
    setupMenu() {
        // Get default navigation
        const menu = this.getUserNavigation(navigation);
        this.fuseNavigation.unregister('main');
        this.fuseNavigation.register('main', menu);
        this.fuseNavigation.setCurrentNavigation('main');
    }
    getUserNavigation(items) {
        if (this.isAdmin('SecurityAdmin')) {
            items[0]
                .children.find(item => item.id === 'aln-productmanagement')
                .children.find(child => child.id === 'aln-Admin')
                .children.find(grandChild => grandChild.id === 'administration')
                .hidden = false;
            items[0]
                .children.find(item => item.id === 'aln-productmanagement')
                .children.find(child => child.id === 'aln-Admin')
                .hidden = false;
            items[0]
                .children.find(item => item.id === 'aln-productmanagement')
                .children.find(child => child.id === 'aln-Admin')
                .children.find(grandChild => grandChild.id === 'settings')
                .hidden = false;
            items[0]
                .children.find(item => item.id === 'aln-productmanagement')
                .children.find(child => child.id === 'aln-Admin')
                .children.find(grandChild => grandChild.id === 'order')
                .hidden = false;
            items[0]
                .children.find(item => item.id === 'aln-productmanagement')
                .children.find(child => child.id === 'aln-Admin')
                .children.find(grandChild => grandChild.id === 'firmPayment')
                .hidden = false;
        }
        return items.slice();
    }
    isAdmin(adminType = 'SecurityAdmin') {
        if (this.currentUserSubject.value) {
            const applicationRoles = this.currentUserSubject.value.applicationRoles;
            if (applicationRoles) {
                const roles = applicationRoles.find(role => role.rolecode === adminType);
                return !!roles;
            }
        }
        return false;
    }
    logout() {
        // remove user from local storage and set current user to null
        localStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
        this.router.navigate(['/auth/login']);
    }
    getSettingsFrmAPI() {
        return this.http.get(`${BASE_URL_WEBAPI}/application-setting/efiling-application-setting-list`)
            .pipe(tap(response => {
            localStorage.setItem('settings', JSON.stringify(response.data));
            this.settingsSubject.next(response.data);
        }));
    }
}
AuthenticationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthenticationService_Factory() { return new AuthenticationService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Router), i0.ɵɵinject(AuthenticationService), i0.ɵɵinject(i3.FuseNavigationService)); }, token: AuthenticationService, providedIn: "root" });
