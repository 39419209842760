import { Component, OnInit } from '@angular/core';
import { ALNIdentityAuthService } from '../aln-identity-auth.service';
import { env } from 'process';
import { securityAdminRole } from '../auth.constants';

@Component({
  selector: 'ef-subscription-modal',
  templateUrl: './subscription-modal.component.html',
  styleUrls: ['./subscription-modal.component.scss']
})
export class SubscriptionModalComponent {

  constructor(private _alnIdentityAuthService: ALNIdentityAuthService) { }

  get isSecurityAdminRole(): boolean {
      if (!this._alnIdentityAuthService.user) {
          return false;
      }

      const { id: currentClientId } = this._alnIdentityAuthService
          .user.allClients.find(c => c.clientId === env.clientId);

      const result = this._alnIdentityAuthService.user.applicationRoles
          .includes(`${securityAdminRole}_${currentClientId}`);

      return result;
  }

}