import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Court } from '../models/court.model';
import { WebApiService } from './web-api.service';
import { GenericActionResult, NewFiling, NewFilingForSubmit, CivilCaseExt, SubsequentFiling, SubsequentFilingForSubmit, CreditCard, AchAccount } from '../models';
import { JtiDocument } from '../models/document-filing.model';
import { PartyDTO } from '../models/PartyDTO.model';
import { environment as env } from '../../../environments/environment';
import { isString } from 'util';
import { FicticiousParty } from '../models/ficticious-PartyName.model';
import { JTICourtOrderFilingDTO } from '../models/JTICourtOrderFilingDTO.model';
import { feeCreateDTO } from '../models/feeCreateDTO.model';
import { JTIFilingAttorneyOrder } from '../models/filing-attorney-order.model';
import { AdditionalDetails } from '../models/additional-details.model';
import { AdditionalAddress } from '../models/additional-address.model';
import { NumberOfParcels } from '../models/number-of-parcels.model';
import { JtiDocumentWithAdditionalDataDTO } from '../models/jti-document-with-additional-data.model';
import { uscfLoginDTO } from '../models/uscfLoginDTO.model';
import { RecentFilings } from '../models/recentfilings.model';
import { EFilingPartyDTO } from '../models/EFilingPartyDTO.model';
import { SearchCaseDTO } from '../models/search-case.model';
import {TylerCaseSearchDTO} from '../models/tylerCaseSearchDTO.model';
import { ServiceContactDTO } from '@app/core/models/service-contactDTO';
import { efilingServiceDTO } from '@app/core/models/efilingServiceDTO';
import { EFilingPartyAlternateName } from '../models/EFilingPartyAlternateName.model';
import { EFilingAttorneyOrder } from '../models/EFiling-attorney-order.model';
import { SubsequentCreateDTO } from '../models/subsequent-filingDTO.model';
import { EFilingAttySearchDTO } from '../models/EFilingAttySearchDTO.model';
import { HttpClient } from '@angular/common/http';
import { personOrOrgSearchList } from '@app/core/models/case-person-search-result.model';
import { customFields } from '../models/custom-fields.model';
import { id } from '@swimlane/ngx-charts/release/utils';
import { optionalServiceDTO } from '../models/optionalServiceDTO.model';
import { OptionalServiceSaveRequestDTO } from '../models/optional-service-save-request-dto.model';
import{ UserExistingCasesDTO } from '../models/userexistingcasesDTO.model';
import { FeeWaiverPaymentAccount } from '../models/feeWaiver-paymentAccount.model';
import{ TylerPaymentAccountDTO } from '../models/tyler-payment-accountDTO.model';
import { TylerAccountTypeDTO } from '../models/tyler-account-typeDTO.model';
const BASE_URL_WEBAPI = env.webApiBaseUri;

const efilingServiceRoutes = {
  centralCourts: '/courts/central-courts',
  courts: '/courts',
  court: (id: string) => `/courts/${id}`,
  Allcourts: (courtID: string) => `/courts/GetAllCourts/${courtID}`,
  caseTypes: '/jti-case-types',
  caseCategories: '/jti-case-categories',
  zipCodes: '/jti-zip-codes',
  courtLocations:'/court-locations',
  languages: '/JtiLanguage',
  getAkaTypes: '/AkaType',
  getJurisAmountlist: '/JurisAmount',
  JurisAmount: '/JurisAmount',
  scanFile: '/scan-file',

  partytype: (id: string) => `/EFilingPartyType/${id}`, 
  pluginType:(id: string) => `/plugin-custom-field/GetPluginsByCourtDivisionId/${id}`,
  partySuffix: (id: string) => `/partySuffix/${id}`,
 
  CreateParty: '/parties',
  updateParty: '/parties',
  getAttorneysByOrderNumber: (ordernumber: number) => `/attorneys/${ordernumber}`,
  getPartiesByOrderNumber: (ordernumber: number) => `/subsequentParties/${ordernumber}`,
  // getPartiesByOrderNumber_nf: (ordernumber: number) => `/parties/${ordernumber}`,
  getPartiesByOrderNumber_NewFiling: (ordernumber: number) => `/NF_parties/${ordernumber}`,
  getEFilingSelfRepresented:(ordernumber: number) => `/selfRepresented/${ordernumber}`,
  ficticiousParty: '/jti-Ficticious-PartyName',
  fillingAttorneyByOrderNumber: '/Jti-filing-attorney-order/',
  getAttorneyListBysearchbar: '/Jti-filing-attorney-order/',
  getSavedAttorneyListByOrderNumber: (ordernumber: string) => `/Jti-filing-attorney-order/${ordernumber}`,
  getPaymentRequest:'/TylerPaymentAccount/GetRequestXML',
  getPaymentAccountTypes:'/TylerTiogaPayment/GetPaymentAccountTypes',
  getPaymentAccount:'/TylerPaymentAccount',
  updatePaymentAccount:'/TylerPaymentAccount/UpdatePaymentAccount',
  getAllPaymentAccounts:'/TylerPaymentAccount/GetAllPaymentAccounts',
  newFilings: '/new-filings',
  optionalService: '/optional-services',
  genNewCaseTitle: '/new-filings/gen-case-title',
  genNewCaseTitle_TY: '/new-filings/ty-gen-case-title',
  subsequentFilings: '/subsequent-filings',
  createFilingFromCivilCaseExt: '/filings/civil-case-ext',
  UpdateNewFiling: '/filings/update-newfiling',
  documentTypes: '/document-types/additional-information',
  documentTypesTyler: '/document-types/additional-information-tyler',
  updateAdditionalInfoOnDocument: (jtiOrderNumber: number, fileNumber: number) => `/new-filings/${jtiOrderNumber}/jti-documents/${fileNumber}/additional-information`,
  serviceContacts: '/service-contact',
  getAllServiceContacts: '/service-contact/GetAllServiceContacts',
  CreateTylerServiceContact:'/service-contact/CreateTylerServiceContact',
  DeleteEFilingServiceContact:'/service-contact/DeleteEFilingServiceContact',
  documentUploads: '/document-uploads',
  documentUploadsPDFUri: '/document-uploads-pdfuri',
  documentUploadsV2: '/document-uploads-v2',
  //updatedocument: '/update-document',

  datetimeFiled: (orderNumber: number) => `/new-filings/${orderNumber}/datetime-filed`,
  users: '/users',
  usersOc: '/users/orange-county',
  usersall: '/users/user-all',
  usersallbyuserFirmId: '/users/user-all-firmId',
  applicationSettingList: '/application-setting/application-setting-list',
  caseQueryByCaseDocketId: (court: string, caseDocketId: string) => `/case-list-queries/${court}/case-docket-id/${caseDocketId}`,
  caseByTrackingId: (court: string, caseTrackingId: string) => `/case-list-queries/${court}/cases/${caseTrackingId}`,
  jtiOrderDetails: (orderNumber: string, transactionNumber: string) => `/new-filings/${orderNumber}/transaction-number/${transactionNumber}`,
  saveUnlawfulDetainerLog: (caseDocketId: string,HasUserSelectedYes:number) => `/unlawful-detainer-log/${HasUserSelectedYes}/case-docket-id/${caseDocketId}`,
  updateEmailAddressByOrderNumber: (ordernumber: string, emailAddress) => `/new-filings/${ordernumber}/emailAddress/${emailAddress}`,
  updateNicknameByVaultId:(id:number, nickName:string) => `/new-filings/id/${id}/nickName/${nickName}`,
  updateCourtLocation: (orderNumber: string, locationCode) => `/new-filings/${orderNumber}/locationCode/${locationCode}`,
  updateAdditionaldetailsByOrderNumber: (ordernumber: string, MessageToClerk: string, clientreferenceCode: string, matterNumber: string, referenceNumber: string) => `/new-filings/${ordernumber}/messagetoClerk/${MessageToClerk}/clientCode/${clientreferenceCode}/matterNumber/${matterNumber}/referenceNumber/${referenceNumber}`,
  updateSecurityLevelData: (ordernumber: string,documentId:string, securityCode: string, filingCode:string) => `/new-filings/update-document/${ordernumber}/${documentId}/${securityCode}/${filingCode}`,
  updateOrderdetailsByOrderNumber: (ordernumber: string, clientReferenceCode: string, matterNumber: string) => `/new-filings/${ordernumber}/clientCode/${clientReferenceCode}/matterNumber/${matterNumber}`,
  updateParcelDetailsByOrderNumber: (ordernumber: string, NumberOfParcels: number) => `/new-filings/${ordernumber}/NumberOfParcels/${NumberOfParcels}`,
  updateApplicationSettingByOrg: (name: string,value: string) => `/application-setting/name/${name}/value/${value}`,
  updateCitationDetailsByOrderNumber: (ordernumber: string, CitationDate: string, CitationIdentification: string) => `/new-filings/${ordernumber}/CitationDate/${CitationDate}/CitationIdentification/${CitationIdentification}`,
  onPartySelectFilerUpdate:(id:number, isFiler:boolean, CourtID: string, EFilingOrderID: string) => `/subsequentParties/id/${id}/isFiler/${isFiler}/CourtID/${CourtID}/EFilingOrderID/${EFilingOrderID}`,
  updateAdditionalAddressByOrderNumber: '/Jti-Additional-Address',
  additionalAddress: '/Jti-Additional-Address',
  getSearchCatList:'/cases/get-search-cat-list',
  getCaseCatList:'/cases/get-case-cat-list',
  getCourtLocationList:'/courts/get-court-location-list',
  courtstates:'/court-states',
  getparties: (id: string) => `/GetParty/${id}`,
  jtidocuments: '/jti-documents',
  getPartyNumber: (ordernumber: string) => `/parties/?ordernumber=${ordernumber}`,
  removeParty: (id: string) => `/parties/?id=${id}`,
  DeletePayment:(id:number)=>`/new-filings/id/${id}`,
  getFee_old: '/fee/generateXmlandPost',
  getFee: '/fee/generateXmlandPost',
  getFeeSusequent: '/fee',
  updatePartyDocumentid: (ordernumber: number, partyId: number, documentid: number) => `/new-filings/${ordernumber}/partyId/${partyId}/documentId/${documentid}`,
  updateDocumentid: (ordernumber: string, documentid: string) => `/new-filings/update-document/${ordernumber}/${documentid}`,
  getFilingFee: (ordernumber: string) => `/fee/${ordernumber}`,
  GetDraftsByUserId: `/courtorder/drafts`,
  // GetSubsequentPartiesByOrderNumber: (ordernumber: string) => `/subsequentparties/${ordernumber}`,

  getFeeByOrderNumber: (ordernumber: string) => `/fee/?ordernumber=${ordernumber}`,
  getDocumentsFeeByEfilingID: (efilingID:number) =>`/subsequentFees/${efilingID}`,
  registerUser: '/users/register',
  forgotPassword: (email:string,token:string) => `/users/forgot-password/${email}/${token}`,
  createFeeWaiver:(paymentAccountTypeCode: string,accountName:string) => `/payment/create-FeeWaiver/${paymentAccountTypeCode}/${accountName}`,
  resetPassword: '/users/reset-password',
  activateAccount: (id: string) => `/users/activate/${id}`,
  creditCards: '/credit-cards',
  creditCardsRemember: (id: number) => `/credit-cards/${id}/remember`,
  updateCreditCardFees: (orderNumber: number) => `/filings/${orderNumber}/credit-card-fees`,
  createEmailServiceFee: (orderNumber: number) => `/filings/${orderNumber}/email-service-fees`,
  createEmailrecipients: (orderNumber: number,ID: number, EmailId: string, sAction: string) => `/filings/${orderNumber}/ID/${ID}/EmailId/${EmailId}/sAction/${sAction}/eService-receipents`,
  achAccounts: '/ach-accounts',
  userCreditCards: '/users/credit-cards',
  userFirmCreditCards: '/users/firm-credit-cards',
  firmCreditCards: '/firm/credit-cards',
  userAchAccounts: '/users/ach-accounts',
  deleteUserAchAccounts: (achAccountId: number) => `/users/ach-accounts/${achAccountId}`,
  CreateUserExistingCases:`/court-order/CreateCentralExistingCase`,
  getRecentFilingListByUserId:  `/court-order/recent-filings`,

  getCompletedFilingListByUserId: `/completed-filings`,
  getBillingReportsListByUserId:`/billing-reports`,
  getCourtDivisionIDForPersonOrOrg: '/case-list-queries/court-case-for-person-org-divisionId',
  getCourtDivisionIDForCase:'/case-list-queries/court-case-divisionId',
  getCourtDivisionIDForCase_Sub:'/case-list-queries/court-case-divisionId_sub',
  CreateParties:'/parties/create-parties',
  getCaseList: '/case-list-queries/case-search',
  getRejectedFilingListByUserId: `/Rejected-filings`,
  getSubmittedFilingListByUserId: `/Submitted-filings`,

  getdocumentsbyOrderNumber: (jtiOrderNumber: number) => `/new-filings/${jtiOrderNumber}/jti-documents/`,
  getOptionalServices: `/optional-services/get-optional-services/`,
  getFilingComponent:`/filing-component/get-filing-component/`,
  getSecurityLevelData:`/security-level/get-security-level/`,
  getDocumentFileType:`/file-type/get-file-type/`,
  getrevieweddocumentsbyOrderNumber: (jtiOrderNumber: number) => `/new-filings/${jtiOrderNumber}/jti-revieweddocuments/`,
  removeDrafts: (orderNumber: number) => `/courtorder/drafts/${orderNumber}`,
  getCaseCategoryByOrderNumber: '/courtorder/getCaseCategoryByOrderNumber',

  EFilingpartytype: (courtDivisionID:string) => `/EFilingpartytype/courtDivisionID/${courtDivisionID}`,
  Requiredpartytype: (courtDivisionID:string, caseTypeCode:string) => `/Requiredpartytype/courtDivisionID/${courtDivisionID}/caseTypeCode/${caseTypeCode}`,
  EFilingPartySuffix: (id: string) => `/EFilingPartySuffix/${id}`,
  CreateEFilingParty: '/EFilingCreateparty',
  EFilingPartyFeeWaiver: (id: string) => `/EFilingPartyFeeWaiver/${id}`,
  CreateEFilingPartyAlternateName:'/EFilingCreatePartyAlternateName',
  getEFilingPartiesByOrderNumber_NewFiling: (EFilingOrderID: string) => `/NF_EFilingParties/${EFilingOrderID}`,
  EFilingFeeWaiverType: (id: string) => `/EFilingFeeWaiverType/${id}`,
  EFilingPartyNameType: (id: string) => `/EFilingPartyNameType/${id}`,
  RemoveEFilingParties: (id: string) => `/RemoveEFilingParties/?id=${id}`,
  UpdateEFilingparties: '/UpdateEFilingparties',
  getEFilingPartyAlternateName: (EFilingOrderID: string) => `/EFilingPartyAlternateName/${EFilingOrderID}`,
  RemoveEFilingPartyAlternateName: (id: string) => `/RemoveEFilingPartyAlternateName/?id=${id}`,
  updateEFilingPartyAlternateName: '/updateEFilingPartyAlternateName',
  EFilingPhoneType: (id: string) => `/EFilingPhoneType/${id}`,
  EFilingAddressType: (id: string) => `/EFilingAddressType/${id}`,
  EFilingStreetType: (id: string) => `/EFilingStreetType/${id}`,
  getUDCaseNumberValidate: '/TylerCaseSearch/validateTylerCaseNumber',
  getTlyerCaseNumberValidate: '/TylerCaseSearch/validateTylerCaseNumber',
  createExistingCaseServices: '/TylerCaseSearch/CreateExistingCaseServices',
  getAttorneyListBysearchbar_N: '/Jti-filing-attorney-order/attorney-list',
  getAttoniesByFirmId: (guidId: string)=>`/FirmManagement/firm/${guidId}`,
  getTylerAttoniesByFirmId: (guidId: string, courtID : string)=>`/FirmManagement/tTaylerfirm/guidId/${guidId}/courtID/${courtID}`,
  deleteAttoneyById: (id:number)=>`/FirmManagement/deleteattorney/${id}`,

  updateFeeDoNotExceedAmtByOrderNumber: (ordernumber: number, doNotExceedAmt:number) => `/new-filings/${ordernumber}/feedoNotExceedAmt/${doNotExceedAmt}`,
  updateCustomFildDetails: '/plugin-custom-field/UpdatePluginsData',
  getpluginData:(id: string) =>  `/plugin-custom-field/getCustomFldPlunginData/${id}`,
  RemoveCustomFldData: (id: string) => `/plugin-custom-field/RemoveCustomFldData/${id}`,
  createFW: '/Payment/create-FeeWaiver',
  paymentAccount: '/Payment/get-paymentAccountList',
  removePaymentAccount:'/Payment/remove-paymentAccount'
};

@Injectable()
export class EfilingService {
  removePaymentAccount(paymentAccountID: string) {
    return this.webApiService.get(`${efilingServiceRoutes.removePaymentAccount}?paymentAccountID=${paymentAccountID}`);
  }
  getPaymentAccountList() {
    return this.webApiService.get(efilingServiceRoutes.paymentAccount); 
  }
    createFeeWaiver(feeWaiverPaymentAccount:FeeWaiverPaymentAccount): Observable<GenericActionResult> {
      const body = {
        accountName:feeWaiverPaymentAccount.accountName,
        paymentAccountTypeCode:feeWaiverPaymentAccount.paymentAccountTypeCode
      }
      return this.webApiService.post(efilingServiceRoutes.createFW, body);;
    }

    getProposedOrderDocData(filingComponentID: string, courtId: string) {
      return this.webApiService.get(`${efilingServiceRoutes.getFilingComponent}?filingComponentID=${filingComponentID}&courtId=${courtId}`);
    }
    updateSecurityLevelData(ordernumber: string, documentId: string, securityCode: string, filingCode:string) {
      return this.webApiService.put(efilingServiceRoutes.updateSecurityLevelData(ordernumber,documentId, securityCode, filingCode));
    }
    createSelectedOptionService(requestDTO: OptionalServiceSaveRequestDTO): Observable<GenericActionResult>{
      return this.webApiService.post(efilingServiceRoutes.optionalService, requestDTO);
    }
    getDocumentFileTypeByCourtID(courtID: string) {
      return this.webApiService.get(`${efilingServiceRoutes.getDocumentFileType}?&courtID=${courtID}`);
    }
    getSecurityLevelData(filingCode: string, courtID: string) {
      return this.webApiService.get(`${efilingServiceRoutes.getSecurityLevelData}?filingCode=${filingCode}&courtID=${courtID}`);
    }
  getOptionalServices(filingCode: string, courtID: string) {
    return this.webApiService.get(`${efilingServiceRoutes.getOptionalServices}?filingCode=${filingCode}&courtID=${courtID}`);
  }

  getCourtDivisionIDForPersonOrOrg(personOrOrgSearchList: personOrOrgSearchList): Observable<GenericActionResult> {
    return this.webApiService.post(efilingServiceRoutes.getCourtDivisionIDForPersonOrOrg, personOrOrgSearchList);
  }
    getCourtDivisionIDForCase(categoryField: string, typeField: string, courtID: string) {
      return this.webApiService.post(`${efilingServiceRoutes.getCourtDivisionIDForCase}?categoryField=${categoryField}&typeField=${typeField}&courtID=${courtID}`);
    }

    getCourtDivisionIDForCase_Sub(categoryField: string, typeField: string, courtID: string) {
      return this.webApiService.post(`${efilingServiceRoutes.getCourtDivisionIDForCase_Sub}?categoryField=${categoryField}&typeField=${typeField}&courtID=${courtID}`);
    }

    CreateUserExistingCases(existingCasesDTO:UserExistingCasesDTO): Observable<GenericActionResult> {
      return this.webApiService.post(efilingServiceRoutes.CreateUserExistingCases, existingCasesDTO);
     }

    getSearchedCases(searchCaseDTO:TylerCaseSearchDTO): Observable<GenericActionResult> {
      return this.webApiService.post(efilingServiceRoutes.getCaseList, searchCaseDTO);
     }
     CreatePartiesFromCivilCaseExt(eFilingID: number): Observable<GenericActionResult> {
           return this.webApiService.post(`${efilingServiceRoutes.CreateParties}?eFilingID=${eFilingID}`);
     }

  constructor( private http: HttpClient,private webApiService: WebApiService) { }

  urlForDocumentUpload(jtiOrderNumber: number, fileNumber: number): string {

    var documentUploadUrl = efilingServiceRoutes.documentUploads;

    if(env.EnablePDFConversionOptimization === "true")
      documentUploadUrl = efilingServiceRoutes.documentUploads;

    return `${BASE_URL_WEBAPI}${efilingServiceRoutes.newFilings}/${jtiOrderNumber}${documentUploadUrl}/${fileNumber}`;
  }

  urlForDocumentUploadByPDFUri(jtiOrderNumber: number, fileNumber: number): string {
    return `${BASE_URL_WEBAPI}${efilingServiceRoutes.newFilings}/${jtiOrderNumber}${efilingServiceRoutes.documentUploadsPDFUri}/${fileNumber}`;
  }

  // updateDocumentbyID(jtiOrderNumber: number, fileNumber: number): string {
  //   return `${BASE_URL_WEBAPI}${efilingServiceRoutes.newFilings}/${jtiOrderNumber}${efilingServiceRoutes.updatedocument}/${fileNumber}`;
  // }

  // updateDocumentbyID(jtiOrderNumber: number, fileNumber: number): Observable<GenericActionResult> {
  //   return this.webApiService.post(efilingServiceRoutes.updateDocumentid1, jtiOrderNumber.toString(), fileNumber.toString());
  // }

  updateDocumentbyID(jtiOrderNumber: number, fileNumber: number) : Observable<GenericActionResult>{
    return this.webApiService.post(efilingServiceRoutes.updateDocumentid(jtiOrderNumber.toString(),fileNumber.toString()));
  }

  getAllCourts(courtID:string): Observable<GenericActionResult> {
    return this.webApiService.get(efilingServiceRoutes.Allcourts(courtID));
  }

  getCentralCourts(EFilingCentralApiBaseUri:string): Observable<GenericActionResult> {
    return this.http.get<GenericActionResult>(`${EFilingCentralApiBaseUri + efilingServiceRoutes.centralCourts}`);
  }


  getCourtById(id: string): Observable<GenericActionResult> {
    return this.webApiService.get(efilingServiceRoutes.courts);
  }

  getPaymentRequestXML(): Observable<GenericActionResult> {   
    return this.webApiService.get(efilingServiceRoutes.getPaymentRequest);
  }
  getPaymentAccountTypes(): Observable<GenericActionResult> {   
    return this.webApiService.get(efilingServiceRoutes.getPaymentAccountTypes);
  }

  getPaymentAccountByID(paymentID: string): Observable<GenericActionResult> {
    return this.webApiService.get(`${efilingServiceRoutes.getPaymentAccount}/${paymentID}`);
  }

  updatePaymentAccount(paymentaccountDTO: TylerPaymentAccountDTO): Observable<GenericActionResult> {       
    return this.webApiService.post(efilingServiceRoutes.updatePaymentAccount, paymentaccountDTO);
  }

 getAllPaymentAccounts(): Observable<GenericActionResult> {   
    return this.webApiService.get(efilingServiceRoutes.getAllPaymentAccounts);
  }
  getCaseTypesByCategoryId(categoryCode:string, courtID:string): Observable<GenericActionResult> {
    return this.webApiService.get(efilingServiceRoutes.caseTypes+ `?categoryCode=${categoryCode}&courtID=${courtID}`);
  }

  GetPartyTypeById(id: string): Observable<GenericActionResult> {
    return this.webApiService.get(efilingServiceRoutes.partytype(id));
  }

  GetPartySuffix(id: string): Observable<GenericActionResult> {
    return this.webApiService.get(efilingServiceRoutes.partySuffix(id));
  }
  
  getCaseCategoryByCourt(courtID: string): Observable<GenericActionResult> {
    return this.webApiService.get(efilingServiceRoutes.caseCategories + `?CourtId=${courtID}`);
  }

  getZipCodesByCaseTypeAndCategory(caseTypeId: string, caseCategoryId: string): Observable<GenericActionResult> {
    return this.webApiService.get(`${efilingServiceRoutes.courtLocations}?CaseTypeId=${caseTypeId}&caseCategoryId=${caseCategoryId}`);
  }
  getNewFilingByOrderNumber(orderNumber: number): Observable<GenericActionResult> {
    return this.webApiService.get(`${efilingServiceRoutes.newFilings}/${orderNumber}`);
  }
  getAllDocumentTypesByCourtDivisionID(courtDivisionID: string): Observable<GenericActionResult> {
   
    return this.webApiService.get(`${efilingServiceRoutes.documentTypes}?courtDivisionID=${courtDivisionID}`);
  }

  saveNewCaseTitle(jtiOrderNumber: number): Observable<GenericActionResult> {
    
   return this.webApiService.get(`${efilingServiceRoutes.genNewCaseTitle}/${jtiOrderNumber}`);
   
  }
  
  saveNewCaseTitle_TY(jtiOrderNumber: number): Observable<GenericActionResult> {
    return this.webApiService.get(`${efilingServiceRoutes.genNewCaseTitle_TY}/${jtiOrderNumber}`);
   }

  createNewFiling(newFiling: NewFiling): Observable<GenericActionResult> {
    const body = {
      courtDivisionID : newFiling.courtDivisionID,
      caseCategoryId: newFiling.selectedCaseCategory.id,
      caseTypeId: newFiling.selectedCaseType.id,
      courtId: newFiling.selectedCourt.id,
      stateId: newFiling.selectedCourtState.id,
      eFilingTypeID:'1'
    };
    return this.webApiService.post(efilingServiceRoutes.newFilings, body);
  }

  CreateFilingFromCivilCaseExt(subsequentCreateDTO: SubsequentCreateDTO): Observable<GenericActionResult> {
    return this.webApiService.post(`${efilingServiceRoutes.createFilingFromCivilCaseExt}`, subsequentCreateDTO);
  }


  getDocumentTypesByCaseTypeAndCategory(caseTypeCode: string, caseCategoryCode: string): Observable<GenericActionResult> {
    //return null;
    return this.webApiService.get(`${efilingServiceRoutes.documentTypes}?caseType=${caseTypeCode}&caseCategory=${caseCategoryCode}`);
  }
  getAllDocumentTypes(): Observable<GenericActionResult> {
    //return null;
    return this.webApiService.get(efilingServiceRoutes.documentTypes);
  }
  getAllDocumentTypesByCourtDivision(selectedCatg:string, selectedTyp:string,InitialFiling:boolean,courtID:string): Observable<GenericActionResult> {
   
    return this.webApiService.get(`${efilingServiceRoutes.documentTypesTyler}?selectedCatg=${selectedCatg}&selectedTyp=${selectedTyp}&InitialFiling=${InitialFiling}&courtID=${courtID}`);
  }
  updateAdditionalInfoOnDocument(jtiOrderNumber: number, fileNumber: number, jtiDocumentWithAdditionalDataDTO: JtiDocumentWithAdditionalDataDTO): Observable<GenericActionResult> {
    return this.webApiService.put(efilingServiceRoutes.updateAdditionalInfoOnDocument(jtiOrderNumber, fileNumber), jtiDocumentWithAdditionalDataDTO);
  }
  getPartiesByOrderNumber(ordernumber: number, filterText: string): Observable<GenericActionResult> {
    return this.webApiService.get(`${efilingServiceRoutes.getPartiesByOrderNumber(ordernumber)}?filterText=${filterText}`);
  }
  getAttorneysByOrderNumber(ordernumber: number, filterText: string): Observable<GenericActionResult> {
    return this.webApiService.get(`${efilingServiceRoutes.getAttorneysByOrderNumber(ordernumber)}?filterText=${filterText}`);
  }
  getPartiesByOrderNumber_NewFiling(ordernumber: number, filterText: string): Observable<GenericActionResult> {
    return this.webApiService.get(`${efilingServiceRoutes.getPartiesByOrderNumber_NewFiling(ordernumber)}?filterText=${filterText}`);
  }
  getEFilingSelfRepresented(ordernumber: number) {   
    return this.webApiService.get(`${efilingServiceRoutes.getEFilingSelfRepresented(ordernumber)}`); 
  }
  getparties(id: string): Observable<GenericActionResult> {
    return this.webApiService.get(efilingServiceRoutes.getparties(id));

  }

  createDocument(jtiDocument: JtiDocument, isNewFiling: boolean): Observable<GenericActionResult> {
    return this.webApiService.post(`${efilingServiceRoutes.newFilings}/${jtiDocument.eFilingID}${efilingServiceRoutes.jtidocuments}?isNewFiling=${isNewFiling}`, jtiDocument);
  }

  createDocumentByPDFUri(jtiDocument: JtiDocument, isNewFiling: boolean): Observable<GenericActionResult> {

    const body = {
        WebLinkUri: "oneDriveUri",
        FileName: "fileName",
    };

    var postUrlForPDFUri = this.urlForDocumentUploadByPDFUri(jtiDocument.eFilingID, jtiDocument.fileNumber);
    return this.webApiService.post(postUrlForPDFUri, body);

    //return this.webApiService.post(`${efilingServiceRoutes.newFilings}/${jtiDocument.orderNumber}${efilingServiceRoutes.jtidocuments}?isNewFiling=${isNewFiling}`, body);
  }

   CreateParty(partyEntity: PartyDTO): Observable<GenericActionResult> {
    const body = {
      firstName: partyEntity.firstName,
      middleName: partyEntity.middleName,
      lastName: partyEntity.lastName,
      addressTypeCode: partyEntity.addressTypeCode,
      partyNumber: partyEntity.partyNumber,
      email: partyEntity.email,
      associatedWithDocuments: partyEntity.associatedWithDocuments,
      partyTypeCode: partyEntity.partyTypeCode,
      firstApperanceFeesPaid: partyEntity.firstApperanceFeesPaid,
      governmentExempt: partyEntity.governmentExempt,
      feeWaiver: partyEntity.feeWaiver,
      languageCode: partyEntity.languageCode,
      nameSuffixCode: partyEntity.nameSuffixCode,
      orderNumber: partyEntity.orderNumber,
      phone: partyEntity.phone.replace('-','').replace('-',''),
      organization: partyEntity.organization,
      requestingFilingFeesExemption: partyEntity.requestingFilingFeesExemption,
      stateCode: partyEntity.stateCode,
      street: partyEntity.street,
      city: partyEntity.city,
      suffixName: partyEntity.suffixName,
      partyDesignationTypeCode: partyEntity.partyDesignationTypeCode,
      thisIsRequired: partyEntity.thisIsRequired,
      zipCode: partyEntity.zipCode,
      addressUnknown: partyEntity.addressUnknown,
      caseTrackingId:partyEntity.caseTrackingID,
      documentId:partyEntity.documentId
    };
    return this.webApiService.post(efilingServiceRoutes.CreateParty, body);
  }
  
  updateParty(partyEntity: PartyDTO): Observable<GenericActionResult> {
    const body = {
      id: partyEntity.id,
      firstName: partyEntity.firstName,
      lastName: partyEntity.lastName,
      addressTypeCode: partyEntity.addressTypeCode,
      partyNumber: partyEntity.partyNumber,
      email: partyEntity.email,
      associatedWithDocuments: partyEntity.associatedWithDocuments,
      partyTypeCode: partyEntity.partyTypeCode,
      firstApperanceFeesPaid: partyEntity.firstApperanceFeesPaid,
      governmentExempt: partyEntity.governmentExempt,
      feeWaiver: partyEntity.feeWaiver,
      languageCode: partyEntity.languageCode,
      nameSuffixCode: partyEntity.nameSuffixCode,
      orderNumber: partyEntity.orderNumber,
      phone: partyEntity.phone.replace('-','').replace('-',''),
      organization: partyEntity.organization,
      requestingFilingFeesExemption: partyEntity.requestingFilingFeesExemption,
      stateCode: partyEntity.stateCode,
      street: partyEntity.street,
      city: partyEntity.city,
      suffixName: partyEntity.suffixName,
      partyDesignationTypeCode: partyEntity.partyDesignationTypeCode,
      thisIsRequired: partyEntity.thisIsRequired,
      zipCode: partyEntity.zipCode,
      addressUnknown: partyEntity.addressUnknown,
    };
    return this.webApiService.put(efilingServiceRoutes.updateParty, body);
  }
  getDocument(orderNumber: number, Id: number): Observable<GenericActionResult> {
    return this.webApiService.get(`${efilingServiceRoutes.newFilings}/${orderNumber}${efilingServiceRoutes.jtidocuments}/${Id}`);
  }
  updateDocument(jtiDocument: JtiDocument): Observable<GenericActionResult> {
    return this.webApiService.put(`${efilingServiceRoutes.newFilings}/${jtiDocument.eFilingID}${efilingServiceRoutes.jtidocuments}/${jtiDocument.fileNumber}`, jtiDocument);
  }
  deleteDocument(jtiOrderNumber: number, fileNumber: number): Observable<GenericActionResult> {
    return this.webApiService.delete(`${efilingServiceRoutes.newFilings}/${jtiOrderNumber}${efilingServiceRoutes.jtidocuments}/${fileNumber}`);
  }
  submitOrder(jtiOrderNumber: number, newFiling: NewFiling): Observable<GenericActionResult> {
    return this.webApiService.post(`${efilingServiceRoutes.newFilings}/${jtiOrderNumber}`, newFiling);
  }

  submitSubsequentOrder(jtiOrderNumber: number, filing: SubsequentFilingForSubmit): Observable<GenericActionResult> {
    return this.webApiService.post(`${efilingServiceRoutes.subsequentFilings}/${jtiOrderNumber}`, filing);
  }
  
  caseQueryByCaseDocketId(court: string, caseDocketId: string): Observable<GenericActionResult> {
    return this.webApiService.get(efilingServiceRoutes.caseQueryByCaseDocketId(court, caseDocketId));
  }
  saveUnlawfulDetainerLog(caseDocketId:string,HasUserSelectedYes:number): Observable<GenericActionResult> {
    return this.webApiService.get(efilingServiceRoutes.saveUnlawfulDetainerLog(caseDocketId,HasUserSelectedYes));
  }
  getAllusers(): Observable<GenericActionResult> {
    return this.webApiService.get(efilingServiceRoutes.usersall);
  }
  getAllUsersByUserFirmId(): Observable<GenericActionResult> {
    return this.webApiService.get(efilingServiceRoutes.usersallbyuserFirmId);
  }
  getApplicationSettingList(): Observable<GenericActionResult> {
    return this.webApiService.get(efilingServiceRoutes.applicationSettingList);
  }
  getCaseByTrackingId(court: string, caseTrackingId: string): Observable<GenericActionResult> {
    return this.webApiService.get(efilingServiceRoutes.caseByTrackingId(court, caseTrackingId));
  }
  getOrderDetails(orderNumber: string, transactionNumber: string): Observable<GenericActionResult> {
    return this.webApiService.get(efilingServiceRoutes.jtiOrderDetails(orderNumber, transactionNumber));
  }
  setDatetimeFiled(jtiOrderNumber: number): Observable<GenericActionResult> {
    return this.webApiService.post(`${efilingServiceRoutes.datetimeFiled(jtiOrderNumber)}`);
  }
  login(username: string, password: string): Observable<GenericActionResult> {
    return this.webApiService.post(`${efilingServiceRoutes.users}`, { username: username, password: password });
  }
  loginOrangeCounty(): Observable<GenericActionResult> {
    return this.webApiService.postWithCookies(`${efilingServiceRoutes.usersOc}`);
  }
  getPartyNumber(ordernumber: string): Observable<GenericActionResult> {
    return this.webApiService.Options(efilingServiceRoutes.getPartyNumber(ordernumber));
  }
  removeParty(id: string) {
    return this.webApiService.delete(efilingServiceRoutes.removeParty(id));
  }

  DeletePayment(id: number) {
    return this.webApiService.delete(efilingServiceRoutes.DeletePayment(id));
  }
 
  getLanguagesByCodeListCode(CodelistCode: string): Observable<GenericActionResult> {
    return this.webApiService.get(efilingServiceRoutes.languages + `?codeListCode=${CodelistCode}`);
  }
  getAkaTypeList(): Observable<GenericActionResult> {
    return this.webApiService.get(efilingServiceRoutes.getAkaTypes);
  }
  CreateFicticiousParty(ficticiousEntity: FicticiousParty[]): Observable<GenericActionResult> {
    // let body = {

    // ficticiousFirstName: ficticiousEntity.ficticiousFirstName,
    // ficticiousLastName: ficticiousEntity.ficticiousLastName,
    // ficticiousMiddleName: ficticiousEntity.ficticiousMiddleName,
    // partyNumber: ficticiousEntity.partyNumber,
    // ficticiousNameTypeCode: ficticiousEntity.ficticiousNameTypeCode,
    // ficticiousNumber: ficticiousEntity.FicticiousNumber,
    // ficticiousPartyDesignationTypeCode: ficticiousEntity.ficticiousPartyDesignationTypeCode,
    // ficticiousOrganization: ficticiousEntity.ficticiousOrganization,
    // fictiousNameSuffixCode: ficticiousEntity.fictiousNameSuffixCode,
    // orderNumber: ficticiousEntity.orderNumber
    // }
    return this.webApiService.post(efilingServiceRoutes.ficticiousParty, ficticiousEntity);
  }

  updateFicticiousParty(ficticiousPartyEntity: FicticiousParty): Observable<GenericActionResult> {
    const body = {
      ficticiousPartyId: ficticiousPartyEntity.ficticiousPartyId,
      ficticiousFirstName: ficticiousPartyEntity.ficticiousFirstName,
      ficticiousLastName: ficticiousPartyEntity.ficticiousLastName,
      ficticiousMiddleName: ficticiousPartyEntity.ficticiousMiddleName,
      partyNumber: ficticiousPartyEntity.partyNumber,
      ficticiousNameTypeCode: ficticiousPartyEntity.ficticiousNameTypeCode,
      ficticiousNumber: ficticiousPartyEntity.ficticiousNumber,
      ficticiousPartyDesignationTypeCode: ficticiousPartyEntity.ficticiousPartyDesignationTypeCode,
      ficticiousOrganization: ficticiousPartyEntity.ficticiousOrganization,
      fictiousNameSuffixCode: ficticiousPartyEntity.fictiousNameSuffixCode,
      orderNumber: ficticiousPartyEntity.orderNumber
    };
    return this.webApiService.put(efilingServiceRoutes.ficticiousParty, body);
  }

  getFicticiousPartyListByOrderNumber(ordernumber: string): Observable<GenericActionResult> {
    return this.webApiService.get(efilingServiceRoutes.ficticiousParty + `?ordernumber=${ordernumber}`);
  }
  deleteficticiousParty(ficticiousPartyId: string): Observable<GenericActionResult> {
    return this.webApiService.delete(`${efilingServiceRoutes.ficticiousParty}?FicticiousPartyId=${ficticiousPartyId}`);
  }
  getJtiJurisAmountByCourtId(CourtId: string): Observable<GenericActionResult> {
    return this.webApiService.get(`${efilingServiceRoutes.getJurisAmountlist}?CourtId=${CourtId}`);
  }
  getJtiJurisAmountByCaseTypeCode(caseTypeCode: string): Observable<GenericActionResult> {
    return this.webApiService.get(`${efilingServiceRoutes.getJurisAmountlist}?caseTypeCode=${caseTypeCode}`);
  }
  getAttorneyList(searchbar: string): Observable<GenericActionResult> {
    return this.webApiService.get(`${efilingServiceRoutes.getAttorneyListBysearchbar}?searchbar=${searchbar}`);
  }
  getSavedAttorneyListByOrdernumber(ordernumber: string): Observable<GenericActionResult> {
    return this.webApiService.get(efilingServiceRoutes.getSavedAttorneyListByOrderNumber(ordernumber));
  }
  removeAttorneyById(Id: string): Observable<GenericActionResult> {
    return this.webApiService.delete(`${efilingServiceRoutes.fillingAttorneyByOrderNumber}?Id=${Id}`);
  }
  postFee(jticourtorderfilingDTO: NewFilingForSubmit): Observable<GenericActionResult> {
    return this.webApiService.post(efilingServiceRoutes.getFee, jticourtorderfilingDTO);
  }

  createFilingAttorneyByOrder(attorneyEntity: EFilingAttorneyOrder): Observable<GenericActionResult> {
    return this.webApiService.post(efilingServiceRoutes.fillingAttorneyByOrderNumber, attorneyEntity);
  }
  GetDraftsByUserId(): Observable<GenericActionResult> {
    return null;// this.webApiService.get(efilingServiceRoutes.GetDraftsByUserId);
  }
  updateAdditionaldetails(ordernumber: string, addtinalDetails: AdditionalDetails): Observable<GenericActionResult> {
    return this.webApiService.put(efilingServiceRoutes.updateAdditionaldetailsByOrderNumber(ordernumber, addtinalDetails.messageToCourt, addtinalDetails.clientFilingCode, addtinalDetails.matterNumber, addtinalDetails.referenceNo));
  }
  updateOrderDetails(ordernumber: string, clientReferenceCode: string, matterNumber: string): Observable<GenericActionResult> {
    return this.webApiService.put(efilingServiceRoutes.updateOrderdetailsByOrderNumber(ordernumber,clientReferenceCode,matterNumber));
  }
  onPartySelectFilerUpdate(id: number, isFiler: boolean, CourtID: string,EFilingOrderID: string): Observable<GenericActionResult> {
    return this.webApiService.put(efilingServiceRoutes.onPartySelectFilerUpdate(id,isFiler,CourtID, EFilingOrderID));
  }

  updateApplicationSetting(name: string,value: string): Observable<GenericActionResult> {
    return this.webApiService.put(efilingServiceRoutes.updateApplicationSettingByOrg(name,value));
  }
  updateEmailAddressByOrderNumber(ordernumber: string, emailAddress: string): Observable<GenericActionResult> {
    return this.webApiService.put(efilingServiceRoutes.updateEmailAddressByOrderNumber(ordernumber,emailAddress));
  }
  updatePremiseAddress(addressDetails: AdditionalAddress): Observable<GenericActionResult> {
    return null;//this.webApiService.put(efilingServiceRoutes.updateAdditionalAddressByOrderNumber, addressDetails);
  }
  getStates(): Observable<GenericActionResult> {
    //return this.webApiService.get(efilingServiceRoutes.additionalAddress);
    return this.webApiService.get(efilingServiceRoutes.courtstates);
  }
  getSearchCatList(): Observable<GenericActionResult> {
    return this.webApiService.get(efilingServiceRoutes.getSearchCatList);
  }
  getCaseCatList(): Observable<GenericActionResult> {
    return this.webApiService.get(efilingServiceRoutes.getCaseCatList);
  }
  getCourtLocationList(): Observable<GenericActionResult> {
    return this.webApiService.get(efilingServiceRoutes.getCourtLocationList);
  }
  getCourtStates(): Observable<GenericActionResult> {
    return this.webApiService.get(efilingServiceRoutes.courtstates);
  }
  updateNumberofParcels(OrderNumber: string, NumberofParcels: number): Observable<GenericActionResult> {
    return this.webApiService.put(efilingServiceRoutes.updateParcelDetailsByOrderNumber(OrderNumber, NumberofParcels));
  }

  updateCitationInfo(OrderNumber: string, CitationDate: Date, ClientReferenceCode: string): Observable<GenericActionResult> {
    return this.webApiService.put(`${efilingServiceRoutes.newFilings}?jtiOrderNumber=${OrderNumber}&CitationDate=${CitationDate}&ClientReferenceCode=${ClientReferenceCode}`);
  }
  getFeeByOrderNumber(ordernumber: string): Observable<GenericActionResult> {
    return this.webApiService.get(efilingServiceRoutes.getFeeByOrderNumber(ordernumber));
  }
  getDocumentsFeeByEfilingID(efilingID: number): Observable<GenericActionResult> {
    return this.webApiService.get(efilingServiceRoutes.getDocumentsFeeByEfilingID(efilingID));
  }
  getFee_subsequent(newFiling: SubsequentFilingForSubmit): Observable<GenericActionResult> {
    return this.webApiService.post(efilingServiceRoutes.getFeeSusequent, newFiling);
  }
  updateConditionallySealed(OrderNumber: string, ConditionallySealed: boolean): Observable<GenericActionResult> {
    return this.webApiService.put(`${efilingServiceRoutes.newFilings}?jtiOrderNumber=${OrderNumber}&ConditionallySealed=${ConditionallySealed}`);
  }
  saveCourtLocation(orderNumber: string, locationCode: string): Observable<GenericActionResult> {
    return this.webApiService.put(efilingServiceRoutes.updateCourtLocation(orderNumber,locationCode));
  }
  registerUser(uscfLoginDTO: uscfLoginDTO): Observable<GenericActionResult> {
    const body = {
      email: uscfLoginDTO.email,
      firmCompany: uscfLoginDTO.firmCompany,
      fName: uscfLoginDTO.fName,
      lName: uscfLoginDTO.lName,
      loginStatusID: uscfLoginDTO.loginStatusID,
      password: uscfLoginDTO.password,
      phoneNumber: uscfLoginDTO.phoneNumber,
      username: uscfLoginDTO.username,
      securityquestion: uscfLoginDTO.securityquestion,
      securityanswer: uscfLoginDTO.securityanswer,
      registrationType:uscfLoginDTO.registrationType
    };
    return this.webApiService.post(efilingServiceRoutes.registerUser, body);
  }

  forgotPassword(email:string, token:string) : Observable<GenericActionResult>{
      return this.webApiService.post(efilingServiceRoutes.forgotPassword(email,token));
  }

  resetPassword(uscfLoginDTO: uscfLoginDTO): Observable<GenericActionResult>{
    const body = {
      email: uscfLoginDTO.email,
      password: uscfLoginDTO.password,
      username: uscfLoginDTO.username,
    };
    return this.webApiService.post(efilingServiceRoutes.resetPassword, body);
  }

  activateAccount(id: string): Observable<GenericActionResult> {
    return this.webApiService.post(efilingServiceRoutes.activateAccount(id));
  }
  addCreditCard(creditCard: CreditCard): Observable<GenericActionResult> {
    return this.webApiService.post(efilingServiceRoutes.creditCards, creditCard);
  }
  saveCreditCardInVault(creditCard: CreditCard): Observable<GenericActionResult> {
    return this.webApiService.post(efilingServiceRoutes.creditCards, creditCard);
  }
  rememberCreditCard(id: number, rememberMe: boolean): Observable<GenericActionResult> {
    return this.webApiService.put(efilingServiceRoutes.creditCardsRemember(id), { rememberMe: rememberMe });
  }
  updateCreditCardFees(orderNumber: number, paymentTypeId: number): Observable<GenericActionResult> {
    return this.webApiService.put(efilingServiceRoutes.updateCreditCardFees(orderNumber), this.webApiService.numberToObjectBody(paymentTypeId));
  }
  createEmailServiceFee(orderNumber: number, emailcount: number): Observable<GenericActionResult> {
    return this.webApiService.put(efilingServiceRoutes.createEmailServiceFee(orderNumber), this.webApiService.numberToObjectBody(emailcount));
  }
  createEmailrecipients(orderNumber: number, ID:number, emailID: string, sAction: string): Observable<GenericActionResult> {
    return this.webApiService.put(efilingServiceRoutes.createEmailrecipients(orderNumber, ID, emailID, sAction));
  }
  getUserCreditCard(): Observable<GenericActionResult> {
    return this.webApiService.get(efilingServiceRoutes.userCreditCards);
  }
  getUserFirmCreditCard(): Observable<GenericActionResult> {
    return this.webApiService.get(efilingServiceRoutes.userFirmCreditCards);
  }
  
  getFirmCreditCards(): Observable<GenericActionResult> {
    return this.webApiService.get(efilingServiceRoutes.firmCreditCards);
  }
  updateNickname(id:number, nickName:string): Observable<GenericActionResult> {
    return this.webApiService.put(`${efilingServiceRoutes.updateNicknameByVaultId(id, nickName)}`);
  }

  addAchAccount(achAccount: AchAccount): Observable<GenericActionResult> {
    return this.webApiService.post(efilingServiceRoutes.achAccounts, achAccount);
  }
  getUserAchAccount(): Observable<GenericActionResult> {
    return this.webApiService.get(efilingServiceRoutes.userAchAccounts);
  }
  deleteUserAchAccounts(achAccountId: number): Observable<GenericActionResult> {
    return this.webApiService.delete(efilingServiceRoutes.deleteUserAchAccounts(achAccountId));
  }

  getRecentFilingListByUserId(): Observable<GenericActionResult> {
    return null;//this.webApiService.get(efilingServiceRoutes.getRecentFilingListByUserId);
  }

  getCompletedFilingListByUserId(): Observable<GenericActionResult> {
    return null;//this.webApiService.get(efilingServiceRoutes.getCompletedFilingListByUserId);
  }
  getBillingReportListByUserId(cnfNumber:string,AlnOrderNumber:string,caseNumber:string,fromDate:string,toDate:string,userNames:string,courtID:string): Observable<GenericActionResult> {
    return this.webApiService.get(`${efilingServiceRoutes.getBillingReportsListByUserId}?cnfNumber=${cnfNumber}&alnOrderNumber=${AlnOrderNumber}&caseNumber=${caseNumber}&fromDate=${fromDate}&toDate=${toDate}&userNames=${userNames}&courtID=${courtID}`);
  }

  getRejectedFilingListByUserId(): Observable<GenericActionResult> {
    return null;// this.webApiService.get(efilingServiceRoutes.getRejectedFilingListByUserId);
  }

  getSubmittedFilingListByUserId(): Observable<GenericActionResult> {
    return null;// this.webApiService.get(efilingServiceRoutes.getSubmittedFilingListByUserId);
  }
  getFilingFeeByOrderNumber(OrderNumber: number): Observable<GenericActionResult> {
    return this.webApiService.get(efilingServiceRoutes.getFeeSusequent + `?orderNumber=${OrderNumber}`);
  }
  getdocumentsbyOrderNumber(OrderNumber: number): Observable<GenericActionResult> {
    return this.webApiService.get(efilingServiceRoutes.getdocumentsbyOrderNumber(OrderNumber));

  }
  getrevieweddocumentsbyOrderNumber(OrderNumber: number): Observable<GenericActionResult> {
    return this.webApiService.get(efilingServiceRoutes.getrevieweddocumentsbyOrderNumber(OrderNumber));

  }
  removeDrafts(orderNumber: number) {
    return this.webApiService.delete(efilingServiceRoutes.removeDrafts(orderNumber));
  }
  getCaseCategoryByOrderNumber(orderNumber: number): Observable<GenericActionResult> {
    return this.webApiService.get(`${efilingServiceRoutes.getCaseCategoryByOrderNumber}/${orderNumber}`);
  }
  updateJurisAmount(OrderNumber: string, JurisAmountCode: string, DemandAmount: number): Observable<GenericActionResult> {
    return this.webApiService.put(`${efilingServiceRoutes.JurisAmount}?jtiOrderNumber=${OrderNumber}&JurisAmountCode=${JurisAmountCode}&DemandAmount=${DemandAmount}`);
  }
  updatePartyDocumentIdAsync(OrderNumber: number, partyId: number, documentId: number): Observable<GenericActionResult> {
    return this.webApiService.put(efilingServiceRoutes.updatePartyDocumentid(OrderNumber,partyId,documentId));//}?jtiOrderNumber=${OrderNumber}&updateDocumentId=${partyId}&documentId=${documentId}`);
  }
  isValidToUpload(formFile: File): Observable<any>{
    return this.webApiService.postV2(`${efilingServiceRoutes.newFilings}${efilingServiceRoutes.scanFile}`, formFile, BASE_URL_WEBAPI, true);
  }

  GetEFilingPartyTypeById(courtDivisionID: string): Observable<GenericActionResult> {
    return this.webApiService.get(efilingServiceRoutes.EFilingpartytype(courtDivisionID));
  }

  GetRequiredPartyTypeById(courtDivisionID: string, caseTypeCode: string): Observable<GenericActionResult> {
    return this.webApiService.get(efilingServiceRoutes.Requiredpartytype(courtDivisionID, caseTypeCode));
  }

  GetEFilingPartySuffix(id: string): Observable<GenericActionResult> {
    return this.webApiService.get(efilingServiceRoutes.EFilingPartySuffix(id));
  }
  GetEFilingPhoneType(id: string): Observable<GenericActionResult> {
    return this.webApiService.get(efilingServiceRoutes.EFilingPhoneType(id));
  }
  GetEFilingAddressType(id: string): Observable<GenericActionResult> {
    return this.webApiService.get(efilingServiceRoutes.EFilingAddressType(id));
  }
  GetEFilingStreetType(id: string): Observable<GenericActionResult> {
    return this.webApiService.get(efilingServiceRoutes.EFilingStreetType(id));
  }
  CreateEFilingParty(partyEntity: EFilingPartyDTO): Observable<GenericActionResult> {
    const body = {
      eFilingOrderID: partyEntity.eFilingOrderID,
      partytypeid : partyEntity.partyTypeID,
      firstName: partyEntity.firstName,
      middleName : partyEntity.middleName,
      lastName: partyEntity.lastName,
      organizationName : partyEntity.organizationName,
      contactPersonName: partyEntity.contactPersonName,
      isBusiness: partyEntity.isBusiness,
      Isfiler: partyEntity.isFiler,
      isSelfRepresented : partyEntity.isSelfRepresented,
      isInterpreterNeeded: partyEntity.isInterpreterNeeded,
      interpreterLanguageID : partyEntity.interpreterLanguageID,
      isFeeWaiverExempted: partyEntity.isFeeWaiverExempted,
      feeWaiverExemptionTypeID : partyEntity.feeWaiverExemptionTypeID,
      emailAddress: partyEntity.emailAddress,
      phoneNumber : partyEntity.phoneNumber.replace('-','').replace('-',''),
      phoneTypeID : partyEntity.phoneTypeID,
      address1: partyEntity.address1,
      streetName : partyEntity.streetName,
      city: partyEntity.city,
      state : partyEntity.state,
      zipCode: partyEntity.zipCode,
      partySuffixTypeID: partyEntity.partySuffixTypeID,
      unitNumber : partyEntity.unitNumber,
      streetSuffixTypeID: partyEntity.streetSuffixTypeID,
      zipExtension: partyEntity.zipExtension,
      faxNumber:partyEntity.faxNumber,
      createdByUserID:partyEntity.createdByUserID,
      documentId:partyEntity.documentId,
      mainDocumentID:partyEntity.mainDocumentID,
      courtId: partyEntity.courtID
    };
    return this.webApiService.post(efilingServiceRoutes.CreateEFilingParty, body);
  }
 
  GetEFilingPartyFeeWaiver(id: string): Observable<GenericActionResult> {
    return this.webApiService.get(efilingServiceRoutes.EFilingPartyFeeWaiver(id));
  }
  CreatePartyAlternateName(PartyAlternateName: EFilingPartyAlternateName[]): Observable<GenericActionResult> {
    return this.webApiService.post(efilingServiceRoutes.CreateEFilingPartyAlternateName, PartyAlternateName);
  }
  getEFilingPartiesByOrderNumber_NewFiling(EFilingOrderID: string, filterText: string): Observable<GenericActionResult> {
    return this.webApiService.get(`${efilingServiceRoutes.getEFilingPartiesByOrderNumber_NewFiling(EFilingOrderID)}?filterText=${filterText}`);
  }
  getEFilingFeeWaiverType(id: string): Observable<GenericActionResult> {
    return this.webApiService.get(efilingServiceRoutes.EFilingFeeWaiverType(id));
  }
  getEFilingPartyNameType(id: string): Observable<GenericActionResult> {
    return this.webApiService.get(efilingServiceRoutes.EFilingPartyNameType(id));
  }
  removeEFilingParty(id: string) {
    return this.webApiService.delete(efilingServiceRoutes.RemoveEFilingParties(id));
  }
  updateEFilingParty(partyEntity: EFilingPartyDTO): Observable<GenericActionResult> {
    const body = {
      id: partyEntity.id,
      eFilingOrderID: partyEntity.eFilingOrderID,
      partytypeid : partyEntity.partyTypeID,
      firstName: partyEntity.firstName,
      middleName : partyEntity.middleName,
      lastName: partyEntity.lastName,
      organizationName : partyEntity.organizationName,
      isBusiness: partyEntity.isBusiness,
      Isfiler: partyEntity.isFiler,
      isSelfRepresented : partyEntity.isSelfRepresented,
      isInterpreterNeeded: partyEntity.isInterpreterNeeded,
      interpreterLanguageID : partyEntity.interpreterLanguageID,
      isFeeWaiverExempted: partyEntity.isFeeWaiverExempted,
      feeWaiverExemptionTypeID : partyEntity.feeWaiverExemptionTypeID,
      emailAddress: partyEntity.emailAddress,
      phoneNumber : partyEntity.phoneNumber.replace('-','').replace('-',''),
      address1: partyEntity.address1,
      streetName : partyEntity.streetName,
      city: partyEntity.city,
      state : partyEntity.state,
      zipCode: partyEntity.zipCode,
      phoneTypeID: partyEntity.phoneTypeID,
      streetSuffixTypeID:partyEntity.streetSuffixTypeID,
      partySuffixTypeID:partyEntity.partySuffixTypeID,
      faxNumber:partyEntity.faxNumber,
      unitNumber:partyEntity.unitNumber,
      zipExtension:partyEntity.zipExtension,
      createdByUserID:partyEntity.createdByUserID, 
      documentId:partyEntity.documentId,
      CCMS_ID: partyEntity.ccmS_ID,
      ASSOC_CCMS_ID: partyEntity.assoC_CCMS_ID,
      courtId: partyEntity.courtID
     };
    return this.webApiService.put(efilingServiceRoutes.UpdateEFilingparties, body);
  }
  getEFilingPartyAlternateName(EFilingOrderID: string): Observable<GenericActionResult> {
    return this.webApiService.get(`${efilingServiceRoutes.getEFilingPartyAlternateName(EFilingOrderID)}`);
  }
  removeEFilingPartyAlternateName(id: string) {
    return this.webApiService.delete(efilingServiceRoutes.RemoveEFilingPartyAlternateName(id));
  }
  updateEFilingPartyAlternateName(ficticiousPartyEntity: EFilingPartyAlternateName): Observable<GenericActionResult> {
    const body = {
      id: ficticiousPartyEntity.id,
      eFilingPartyID:ficticiousPartyEntity.eFilingPartyID,
      partyNameTypeID:ficticiousPartyEntity.partyNameTypeID,
      firstName:ficticiousPartyEntity.firstName,
      middleName: ficticiousPartyEntity.middleName,
      lastName:ficticiousPartyEntity.lastName,
    };
    return this.webApiService.put(efilingServiceRoutes.updateEFilingPartyAlternateName, body);
  }
  getPluginsByCourtDivisionId(divisionId: string): Observable<GenericActionResult> {
    return this.webApiService.get(efilingServiceRoutes.pluginType(divisionId));
  }
getUDCaseNumberValidate(searchCaseDTO:SearchCaseDTO): Observable<GenericActionResult> {
    return this.webApiService.post(efilingServiceRoutes.getUDCaseNumberValidate, searchCaseDTO);
   }
  
   deleteEFilingServiceContact(serviceDTO:efilingServiceDTO): Observable<GenericActionResult> {
    return this.webApiService.post(efilingServiceRoutes.DeleteEFilingServiceContact, serviceDTO);
   }

   getTylerCaseDetails(searchCaseDTO:SearchCaseDTO): Observable<GenericActionResult> {
    return this.webApiService.post(efilingServiceRoutes.getTlyerCaseNumberValidate, searchCaseDTO);
   }

   CreateExistingCaseServices(serviceDTO:efilingServiceDTO): Observable<GenericActionResult> {
    return this.webApiService.post(efilingServiceRoutes.createExistingCaseServices, serviceDTO);
   }

getAttorneyList_New(partyEntity: EFilingAttySearchDTO): Observable<GenericActionResult> {
    const body = {
      firstName: partyEntity.firstName,
      lastName : partyEntity.lastName,
      organizationName: partyEntity.organizationName,
      firstNameSearchType : partyEntity.firstNameSearchType,
      lastNameSearchType: partyEntity.lastNameSearchType,
      organizationNameSearchType : partyEntity.organizationNameSearchType,
   };
    return this.webApiService.post(efilingServiceRoutes.getAttorneyListBysearchbar_N, body);
  }
  getAttonies( guidId: string): Observable<GenericActionResult> {
    return this.webApiService.get(`${efilingServiceRoutes.getAttoniesByFirmId(guidId)}`);
  }
  getTylerAttornies( guidId: string, courtID : string ): Observable<GenericActionResult> {
    return this.webApiService.put(`${efilingServiceRoutes.getTylerAttoniesByFirmId(guidId, courtID)}`);
  }
  deleteAttorney( id: number): Observable<GenericActionResult> {
    return this.webApiService.delete(`${efilingServiceRoutes.deleteAttoneyById(id)}`);
  }
  updateFeeDoNotExceedAmtByOrderNumber(ordernumber: number, doNotExceedAmt: number): Observable<GenericActionResult> {
    return this.webApiService.put(efilingServiceRoutes.updateFeeDoNotExceedAmtByOrderNumber(ordernumber,doNotExceedAmt));
  }
  updatecustomFilddetails(customFildData: customFields[]): Observable<GenericActionResult> {
    return this.webApiService.post(efilingServiceRoutes.updateCustomFildDetails, customFildData);
  }
  getPluginsData(eFilingID: string): Observable<GenericActionResult> {
    return this.webApiService.get(efilingServiceRoutes.getpluginData(eFilingID));
  }
  removeCustomFild(id: string) {
    return this.webApiService.delete(efilingServiceRoutes.RemoveCustomFldData(id));
  }

  getEFilingServiceContacts(orderNumber: number): Observable<GenericActionResult> {
    return this.webApiService.get(`${efilingServiceRoutes.serviceContacts}/${orderNumber}`);
  }
  CreateEFilingServiceContacts(serviceContactID:string, orderNumber: number,caseGUID:string): Observable<GenericActionResult> {
    return this.webApiService.post(`${efilingServiceRoutes.serviceContacts}/EFiling-serviceContact/${serviceContactID}/${orderNumber}/${caseGUID}`);
  }


  getAllServiceContacts(): Observable<GenericActionResult> {
    return this.webApiService.get(`${efilingServiceRoutes.getAllServiceContacts}`);
  }

  UpdateNewFiling(newFiling: NewFiling): Observable<GenericActionResult> {
    const body = {
      ID : newFiling.jtiOrderNumber,
      courtDivisionID : newFiling.courtDivisionID,
      caseCategoryId: newFiling.selectedCaseCategory.id,
      caseTypeId: newFiling.selectedCaseType.id,
      courtId: newFiling.selectedCourt.id,
      stateId: newFiling.selectedCourtState.id,
      eFilingTypeID:'1'
    };
    return this.webApiService.post(efilingServiceRoutes.UpdateNewFiling, body);
  }

  CreateTylerServiceContact(serviceContactEntity: ServiceContactDTO): Observable<GenericActionResult> {
    const body = {
      firstName: serviceContactEntity.firstName,
      middleName: serviceContactEntity.middleName,
      lastName: serviceContactEntity.lastName,
      email:serviceContactEntity.email,
      phone:serviceContactEntity.phone,
      addressLine1:serviceContactEntity.addressLine1,
      addressLine2:serviceContactEntity.addressLine2,
      city:serviceContactEntity.city,
      state:serviceContactEntity.state,
      zipCode:serviceContactEntity.zipCode,
      addressNotSpecified:serviceContactEntity.addressNotSpecified,
      isPublic:serviceContactEntity.isPublic
           
    };
    return this.webApiService.post(efilingServiceRoutes.CreateTylerServiceContact, body);
  }

}

