export const environment = {
    production: false,
    hmr       : false,
    eFilingCentralAppBaseUri: 'https://central.efiling.dev.alncorp.com',
    eFilingCentralApiBaseUri: 'https://apicentral.efiling.dev.alncorp.com/api',
    webApiBaseUri: 'https://api.efiling.cs.alncorp.com/api',
    centralAppBaseUri: 'https://apps.dev.alncorp.com',
    centralApiBaseUri: 'https://api.apps.dev.alncorp.com',
    EnablePDFConversionOptimization: 'true',
    stsUrl: 'https://identity.dev.alncorp.com/',
    clientId: 'eFiling',
    redirectRoute: '/appgateway/home',
    // clientUrl: 'https://ty.efiling.dev.alncorp.com/',
    clientUrl: 'https://efiling.cs.alncorp.com',
    silentRenewRoute: 'silent-renew.html',
    logConsoleDebugActive: true,
};
