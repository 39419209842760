import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ProgressData } from './progress-data';

@Component({
  selector: 'app-efiling-progress',
  templateUrl: './efiling-progress.component.html',
  styleUrls: ['./efiling-progress.component.scss']
})
export class EFilingProgressComponent implements OnInit {

  uploadProgress = -1;
  mode = 'determinate';

  constructor(
    public dialogRef: MatDialogRef<EFilingProgressComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ProgressData) { }

  ngOnInit(): void {
    this.mode = this.data.mode;
    if (this.data.progress) {
      this.data.progress.subscribe(p => {
        this.uploadProgress = p;
        if (this.uploadProgress === 100) {
          this.dialogRef.close(this.uploadProgress);
        }
      });
    }
  }

}
