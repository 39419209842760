import { Injectable, Directive } from '@angular/core';
@Injectable()
export class GlobalServiceDTO {
    private sNDTOSelected: any [] = [];
    private sEDTOSelected: any [] = [];
    private subFiling: any[] = [];

    constructor() { }
   
    setNDTOSelectedVal(val) {
        this.sNDTOSelected = val;
    }
    getNDTOSelectedVal() {
        return this.sNDTOSelected;
    }

    setEDTOSelectedVal(val) {
        this.sEDTOSelected = val;
    }
    getEDTOSelectedVal() {
        return this.sEDTOSelected;
    }

    setsubFiling(val) {
        this.subFiling = val;
    }
    getsubFiling() {
        return this.subFiling;
    }
}