+<mat-dialog-content>
    +    <span *ngIf="isSecurityAdminRole">
    +        This feature is not subscribed, Click below to subscribe.
    +    </span>
    +    <span *ngIf="!isSecurityAdminRole">
    +        Please contact your administrator for this feature.
    +    </span>
    +</mat-dialog-content>
    +<mat-dialog-actions>
    +    <a
    +        href="https://admin.alncorp.com/subscriptions/manage"
    +        target="_blank"
    +        *ngIf="isSecurityAdminRole"
    +        mat-button
    +    >
    +        Subscribe
    +    </a>
    +    <button mat-button [mat-dialog-close]="true">Cancel</button>
    +</mat-dialog-actions>