/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/flex-layout/flex";
import * as i2 from "@angular/flex-layout/core";
import * as i3 from "../../../node_modules/@angular/material/progress-spinner/typings/index.ngfactory";
import * as i4 from "@angular/material/progress-spinner";
import * as i5 from "@angular/cdk/platform";
import * as i6 from "@angular/common";
import * as i7 from "@angular/platform-browser/animations";
import * as i8 from "./appgateway.component";
import * as i9 from "@angular/router";
import * as i10 from "./services/authentication.service";
import * as i11 from "./auth/aln-identity-auth.service";
var styles_AppGatewayComponent = [];
var RenderType_AppGatewayComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppGatewayComponent, data: {} });
export { RenderType_AppGatewayComponent as RenderType_AppGatewayComponent };
export function View_AppGatewayComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["fxLayout", "row"], ["fxLayoutAlign", "space-around center"], ["style", "width:100%;height:100%"]], null, null, null, null, null)), i0.ɵdid(1, 671744, null, 0, i1.DefaultLayoutDirective, [i0.ElementRef, i2.StyleUtils, [2, i1.LayoutStyleBuilder], i2.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i0.ɵdid(2, 671744, null, 0, i1.DefaultLayoutAlignDirective, [i0.ElementRef, i2.StyleUtils, [2, i1.LayoutAlignStyleBuilder], i2.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 1, "mat-spinner", [["class", "mat-spinner mat-progress-spinner"], ["diameter", "50"], ["mode", "indeterminate"], ["role", "progressbar"], ["strokeWidth", "5"]], [[2, "_mat-animation-noopable", null], [4, "width", "px"], [4, "height", "px"]], null, null, i3.View_MatSpinner_0, i3.RenderType_MatSpinner)), i0.ɵdid(4, 49152, null, 0, i4.MatSpinner, [i0.ElementRef, i5.Platform, [2, i6.DOCUMENT], [2, i7.ANIMATION_MODULE_TYPE], i4.MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS], { diameter: [0, "diameter"], strokeWidth: [1, "strokeWidth"] }, null)], function (_ck, _v) { var currVal_0 = "row"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "space-around center"; _ck(_v, 2, 0, currVal_1); var currVal_5 = "50"; var currVal_6 = "5"; _ck(_v, 4, 0, currVal_5, currVal_6); }, function (_ck, _v) { var currVal_2 = i0.ɵnov(_v, 4)._noopAnimations; var currVal_3 = i0.ɵnov(_v, 4).diameter; var currVal_4 = i0.ɵnov(_v, 4).diameter; _ck(_v, 3, 0, currVal_2, currVal_3, currVal_4); }); }
export function View_AppGatewayComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "redirect", [], null, null, null, View_AppGatewayComponent_0, RenderType_AppGatewayComponent)), i0.ɵdid(1, 114688, null, 0, i8.AppGatewayComponent, [i9.ActivatedRoute, i10.AuthenticationService, i11.ALNIdentityAuthService, i9.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppGatewayComponentNgFactory = i0.ɵccf("redirect", i8.AppGatewayComponent, View_AppGatewayComponent_Host_0, {}, {}, []);
export { AppGatewayComponentNgFactory as AppGatewayComponentNgFactory };
