export class User {
    userId: string;
    userName: string;
    fName: string;
    lName: string;
    profileImageURL: string;
    bearerToken: string;
    claims: any[];
    organisation: any;
    applicationRoles: any[];
    hasOnetimeImportCompleted: boolean;
    fullName: string;
    clients: any;
    allClients: any[];
}
