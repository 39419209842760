import { Component, Inject, ElementRef } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { version } from '../../../../../package.json';

@Component({
    selector   : 'footer',
    templateUrl: './footer.component.html',
    styleUrls  : ['./footer.component.scss']
})
export class FooterComponent
{
    /**
     * Constructor
     */
    constructor(@Inject(DOCUMENT) private document, private elementRef: ElementRef){}
    
    appVersion = version;

    ngAfterViewInit() {
        var script=document.createElement("script");
        script.type="text/javascript";
        script.src="https://wchat.freshchat.com/js/widget.js"; //external script
        this.elementRef.nativeElement.appendChild(script);

        var freshChatJS=document.createElement("script");
        freshChatJS.type="text/javascript";
        freshChatJS.src = 'assets/fresh-chat/FreshChat.js';
        this.elementRef.nativeElement.appendChild(freshChatJS);
    }
}
