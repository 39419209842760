import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { AuthGuard } from './core/guards';
import { BsModalModule } from 'ng2-bs3-modal';
import { AppGatewayComponent } from './core/appgateway.component';
import { RegistrationGuard } from './core/guards/registration.guard';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'dashboard', canActivate: [AuthGuard] },
  { path: 'new-filing', loadChildren: 'app/modules/new-filing/new-filing.module#NewFilingModule', canActivate: [AuthGuard] },
  { path: 'products', loadChildren: 'app/modules/products/products.module#ProductsModule', canActivate: [AuthGuard] },
  { path: 'product-efiling', loadChildren: 'app/modules/product-efiling/product-efiling.module#ProductEfilingModule', canActivate: [AuthGuard] },
  { path: 'subsequent', loadChildren: 'app/modules/subsequent-filing/subsequent-filing.module#SubsequentfilingModule', canActivate: [AuthGuard] },
  { path: 'recent-filings', loadChildren: 'app/modules/recent-filings/recent-filings.module#RecentFilingsModule', canActivate: [AuthGuard] },
  { path: 'completed-filings', loadChildren: 'app/modules/completed-filings/completed-filings.module#CompletedFilingsModule', canActivate: [AuthGuard] },
  { path: 'displayorder', loadChildren: 'app/modules/displayorder/displayorder.module#DisplayOrderModule', canActivate: [AuthGuard] },
  { path: 'order-update', loadChildren: 'app/modules/order-update/order-update.module#OrderUpdateModule', canActivate: [AuthGuard] },
  { path: 'appgateway/:landingpage', component: AppGatewayComponent, pathMatch: 'full'},
  { path: 'pages', loadChildren: './main/pages/pages.module#PagesModule' },
  { path: 'maintain-payment', loadChildren:'./modules/maintain-payments/maintain-payment.module#MaintainPaymentModule', canActivate: [AuthGuard] },
  { path: 'update-payment', loadChildren:'./modules/maintain-payments/update-payment-account.module#UpdatePaymentModule', canActivate: [AuthGuard] }
];


@NgModule({
  imports: [
      BsModalModule,
      RouterModule.forRoot(routes, {
        onSameUrlNavigation: 'reload',
        preloadingStrategy: PreloadAllModules
      })
    ],
  exports: [RouterModule],
  providers: [
    AuthGuard
  ]
})
export class
  AppRoutingModule {
}
