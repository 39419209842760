import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ALNIdentityAuthService } from '../auth/aln-identity-auth.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(private auth: ALNIdentityAuthService) { }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        /* const Token = localStorage.getItem("bearerToken");
        if (bearerToken) {
          request = request.clone({
            headers: request.headers.set("Authorization", `Bearer ${bearerToken}`)
          });
        }
        return next.handle(request); */
        if (this.auth && this.auth.user && this.auth.user.bearerToken) {
            // Get the auth token from the service.
            const authToken = this.auth.user.bearerToken;

            // Clone the request and replace the original headers with
            // cloned headers, updated with the authorization.
            const authReq = req.clone({
                headers: req.headers.set('Authorization', 'Bearer ' + authToken)
            });

            // send cloned request with header to the next handler.
            return next.handle(authReq);
        }
        else
            return next.handle(req);

    }
}
