import { Injectable } from '@angular/core';
import { ALNIdentityAuthService } from '../auth/aln-identity-auth.service';

@Injectable()
export class NavService {
    constructor(
        private _authIdentityAuthService: ALNIdentityAuthService) {
    }
  get isLoggedIn(): boolean {
      if (this._authIdentityAuthService.isAuthorized) {
      return true;
    }
    return false;
  }

  public clearBearerToken(): void {
    localStorage.removeItem('bearerToken');
  }
  
}

