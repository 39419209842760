import { User } from 'app/core/models/user';
import { ALNIdentityAuthUser } from './aln-identity-user';
import { environment as env } from '../../../environments/environment';
import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "angular-auth-oidc-client";
export class ALNIdentityAuthService {
    constructor(oidcSecurityService, _tokenHelperService) {
        this.oidcSecurityService = oidcSecurityService;
        this._tokenHelperService = _tokenHelperService;
        this.authorizedSource = new Subject();
        this._isAuthorized = false;
        this._user = new User();
        this.authorized$ = this.authorizedSource.asObservable();
        this.oidcSecurityService.getIsAuthorized().subscribe((isAuthorized) => {
            this._isAuthorized = isAuthorized;
            if (isAuthorized) {
                let token = this.oidcSecurityService.getToken();
                console.log(token);
                let userData = this._tokenHelperService.getPayloadFromToken(token, false);
                this.mapUser(userData);
            }
            else {
                this._user = new User();
            }
            this.authorizedSource.next(isAuthorized);
        });
        if (this.oidcSecurityService.moduleSetup) {
            this.doCallbackLogicIfRequired();
        }
        else {
            this.oidcSecurityService.onModuleSetup.subscribe(() => {
                this.doCallbackLogicIfRequired();
            });
        }
    }
    get isAuthorized() {
        return this._isAuthorized;
    }
    get user() {
        if (!this.isAuthorized) {
            this._user = new User();
        }
        return this._user;
    }
    login() {
        this.oidcSecurityService.authorize();
    }
    logout() {
        this.oidcSecurityService.logoff();
    }
    doCallbackLogicIfRequired() {
        this.oidcSecurityService.authorizedCallbackWithCode(window.location.toString());
    }
    mapUser(userData) {
        const parseObj = (values) => Array.isArray(values)
            ? values.map(v => JSON.parse(v))
            : [JSON.parse(values)];
        const userInfo = new ALNIdentityAuthUser(userData);
        this._user.userId = userInfo.userId;
        this._user.userName = userInfo.preferred_username;
        this._user.fullName = userInfo.name;
        this._user.profileImageURL = userInfo.profilePictureUrl.startsWith("https://")
            ? userInfo.profilePictureUrl
            : `${env.stsUrl}${userInfo.profilePictureUrl}`;
        this._user.bearerToken = this.oidcSecurityService.getToken();
        this._user.organisation = userInfo.organizationName;
        this._user.applicationRoles = Array.isArray(userInfo.userRoles)
            ? userInfo.userRoles
            : [userInfo.userRoles];
        this._user.clients = parseObj(userInfo.products);
        this._user.allClients = parseObj(userInfo.allProducts);
        localStorage.setItem('currentUser', JSON.stringify(this._user));
    }
}
ALNIdentityAuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ALNIdentityAuthService_Factory() { return new ALNIdentityAuthService(i0.ɵɵinject(i1.OidcSecurityService), i0.ɵɵinject(i1.TokenHelperService)); }, token: ALNIdentityAuthService, providedIn: "root" });
