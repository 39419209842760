import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '@env/environment';
import { AuthenticationService } from './services/authentication.service';
import { User } from './models/user';
import { Subject, pipe, merge } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ALNIdentityAuthService } from './auth/aln-identity-auth.service';

@Component({
    selector: 'redirect',
    template: '<div fxLayout="row" fxLayoutAlign="space-around center" style="width:100%;height:100%"><mat-spinner diameter="50" strokeWidth="5"></mat-spinner></div>'
})
export class AppGatewayComponent implements OnInit {

    token: string;
    orderNumber: string;
    caseNumber: string;
    EFilingCentralAppBaseUri: string;
    courtID: string;

    currentUser: User;
    landingPage: string;
    private _unsubscribeAll: Subject<any>;

    constructor(
        private route: ActivatedRoute,
        private authenticationService: AuthenticationService,
        private alnIdentityAuthService: ALNIdentityAuthService,
        private router: Router) { }

    ngOnInit(): void {
        this.token = this.route.snapshot.queryParams.token;
        this.landingPage = this.route.snapshot.params['landingpage'];
        this.orderNumber = this.route.snapshot.queryParams.orderNumber;
        this.caseNumber = this.route.snapshot.queryParams.caseNumber;
        this.courtID = this.route.snapshot.queryParams.courtID;
        this.currentUser = this.authenticationService.currentUserValue;
        this.EFilingCentralAppBaseUri = environment.eFilingCentralAppBaseUri;

        this.alnIdentityAuthService.authorized$.subscribe((auth) => {

            if (auth === false)
                return;
            this.currentUser = this.alnIdentityAuthService.user;

            this.landingPage = this.route.snapshot.params['landingpage'];

            if (!this.authenticationService.eFilingSettings)
                this.authenticationService.getSettingsFrmAPI().subscribe(() => {
                    this.redirect();
                })
            else
                this.redirect();
        });

    }

    redirect() {

        const dashboard = this.route.snapshot.queryParams.dashboard;
        const orderNumber = this.route.snapshot.queryParams.orderNumber;
        const caseNumber = this.route.snapshot.queryParams.caseNumber;

        if (this.landingPage === 'home') {
            this.router.navigate['/dashboard'];
        }
        else if (this.landingPage === 'mail') {
            window.location.href = `${environment.centralAppBaseUri}/apps/appgateway/${this.currentUser.userId}/mail`;
        }
        else if (this.landingPage === 'calendar') {
            window.location.href = `${environment.centralAppBaseUri}/apps/appgateway/${this.currentUser.userId}/calendar`;
        }
        else if (this.landingPage === 'new-filing' || this.landingPage === 'subsequent') {
            const efilingRedirectURL = this.authenticationService.eFilingSettings.find(x => x.key == 'EFilingOCAppBaseUri').defaultValue;
            const landing = this.landingPage.includes('subsequent') ? '/subsequent' : '/new-filing';
            if (dashboard == "OC") {
                if (caseNumber) {
                    window.location.href = `${efilingRedirectURL + '/appgateway' + landing}?caseNumber=${caseNumber}&token=${this.currentUser.bearerToken}`;
                }
                else {
                    window.location.href = `${efilingRedirectURL + '/appgateway' + landing}?orderNumber=${orderNumber}&token=${this.currentUser.bearerToken}`;
                }
            }
            else//LA 
            {
                if (caseNumber) {
                    this.router.navigate([this.landingPage.includes('subsequent') ? `/subsequent/filing/case/${caseNumber}` : `/new-filing/${orderNumber}`], { relativeTo: this.route });
                }
                else {
                    this.router.navigate([this.landingPage.includes('subsequent') ? `/subsequent/filing/${orderNumber}` : `/new-filing/${orderNumber}`], { relativeTo: this.route });
                }
            }
        } else {
            window.location.href = `${environment.eFilingCentralAppBaseUri}/${this.landingPage}`
        }
    }

    getCaseRedirectUrl(): string {
        if (this.orderNumber)
            return this.landingPage.includes('subsequent') ? `/subsequent/filing/${this.orderNumber}` : `/new-filing/${this.orderNumber}`;
        else if (this.caseNumber)
            return this.landingPage.includes('subsequent') ? `/subsequent/filing/case/${this.caseNumber}` : `/new-filing/${this.caseNumber}`;
        else if (this.courtID)
            return this.landingPage.includes('subsequent') ? `/subsequent/filing/courtID/${this.courtID}` : `/new-filing/courtID/${this.courtID}`;
        else
            return this.landingPage.includes('subsequent') ? '/subsequent/filing' : '/new-filing';
    }
}
