import { User } from 'app/core/models/user';
import { ClientDto } from './models/client';

export class ALNIdentityAuthUser {
    preferred_username: string;
    email: string;
    userId: string;
    name: string;
    fullName: string;
    organizationName: string;
    profilePictureUrl: string;
    userRoles: string | string[];
    products: string | string[];
    allProducts: ClientDto[]
    sub: string;
    constructor(value: any) {
        Object.assign(this, value);
    }
}