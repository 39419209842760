<mat-toolbar>

    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutAlign.gt-xs="space-between center" fxFlex>      

        <div fxLayout="row" fxLayoutAlign="start center" fxHide fxShow.gt-xs>
        </div>
        <div class="appVersion">
                <div>{{ appVersion }}</div> 
        </div>
    </div>

</mat-toolbar>
