/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./subscription-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/a11y";
import * as i5 from "@angular/platform-browser/animations";
import * as i6 from "@angular/material/dialog";
import * as i7 from "@angular/common";
import * as i8 from "./subscription-modal.component";
import * as i9 from "../aln-identity-auth.service";
var styles_SubscriptionModalComponent = [i0.styles];
var RenderType_SubscriptionModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SubscriptionModalComponent, data: {} });
export { RenderType_SubscriptionModalComponent as RenderType_SubscriptionModalComponent };
function View_SubscriptionModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" + This feature is not subscribed, Click below to subscribe. + "]))], null, null); }
function View_SubscriptionModalComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" + Please contact your administrator for this feature. + "]))], null, null); }
function View_SubscriptionModalComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "a", [["+", ""], ["href", "https://admin.alncorp.com/subscriptions/manage"], ["mat-button", ""], ["target", "_blank"]], [[1, "tabindex", 0], [1, "disabled", 0], [1, "aria-disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1)._haltDisabledEvents($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatAnchor_0, i2.RenderType_MatAnchor)), i1.ɵdid(1, 180224, null, 0, i3.MatAnchor, [i4.FocusMonitor, i1.ElementRef, [2, i5.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, [" + Subscribe + "]))], null, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled ? (0 - 1) : (i1.ɵnov(_v, 1).tabIndex || 0)); var currVal_1 = (i1.ɵnov(_v, 1).disabled || null); var currVal_2 = i1.ɵnov(_v, 1).disabled.toString(); var currVal_3 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
export function View_SubscriptionModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["+"])), (_l()(), i1.ɵeld(1, 0, null, null, 8, "mat-dialog-content", [["class", "mat-dialog-content"]], null, null, null, null, null)), i1.ɵdid(2, 16384, null, 0, i6.MatDialogContent, [], null, null), (_l()(), i1.ɵted(-1, null, [" + "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SubscriptionModalComponent_1)), i1.ɵdid(5, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, [" + "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SubscriptionModalComponent_2)), i1.ɵdid(8, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, [" +"])), (_l()(), i1.ɵted(-1, null, [" +"])), (_l()(), i1.ɵeld(11, 0, null, null, 10, "mat-dialog-actions", [["class", "mat-dialog-actions"]], null, null, null, null, null)), i1.ɵdid(12, 16384, null, 0, i6.MatDialogActions, [], null, null), (_l()(), i1.ɵted(-1, null, [" + "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SubscriptionModalComponent_3)), i1.ɵdid(15, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, [" + "])), (_l()(), i1.ɵeld(17, 0, null, null, 3, "button", [["mat-button", ""], ["type", "button"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 19).dialogRef.close(i1.ɵnov(_v, 19).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(18, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(19, 606208, null, 0, i6.MatDialogClose, [[2, i6.MatDialogRef], i1.ElementRef, i6.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵted(-1, 0, ["Cancel"])), (_l()(), i1.ɵted(-1, null, [" +"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isSecurityAdminRole; _ck(_v, 5, 0, currVal_0); var currVal_1 = !_co.isSecurityAdminRole; _ck(_v, 8, 0, currVal_1); var currVal_2 = _co.isSecurityAdminRole; _ck(_v, 15, 0, currVal_2); var currVal_6 = true; _ck(_v, 19, 0, currVal_6); }, function (_ck, _v) { var currVal_3 = (i1.ɵnov(_v, 18).disabled || null); var currVal_4 = (i1.ɵnov(_v, 18)._animationMode === "NoopAnimations"); var currVal_5 = (i1.ɵnov(_v, 19).ariaLabel || null); _ck(_v, 17, 0, currVal_3, currVal_4, currVal_5); }); }
export function View_SubscriptionModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ef-subscription-modal", [], null, null, null, View_SubscriptionModalComponent_0, RenderType_SubscriptionModalComponent)), i1.ɵdid(1, 49152, null, 0, i8.SubscriptionModalComponent, [i9.ALNIdentityAuthService], null, null)], null, null); }
var SubscriptionModalComponentNgFactory = i1.ɵccf("ef-subscription-modal", i8.SubscriptionModalComponent, View_SubscriptionModalComponent_Host_0, {}, {}, []);
export { SubscriptionModalComponentNgFactory as SubscriptionModalComponentNgFactory };
