import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
    {
        id       : 'efiling-dash',
        title    : 'e-Filing',
        translate: 'NAV.EFILE.TITLE',
        type     : 'group',
        icon     : 'dash',
        children : [
            {
                id       : 'dashboard',
                title    : 'Dashboard',
                translate: 'NAV.DASHBOARD.TITLE',
                type     : 'item',
                icon     : 'dashboard',
                url      : '/appgateway/dashboard'                
            },
            {
                id      : 'new-case',
                title   : 'New Case Filing',
                type    : 'item',
                url     : '/new-filing',
                icon    : 'note_add'
            },
            {
                id      : 'subsequent',
                title   : 'Subsequent Filing',
                type    : 'item',
                url     : '/subsequent/filing',
                icon    : 'description'
            },
            // {
            //     id       : 'maintainPayment',
            //     title    : 'Manage Tyler Payments',
            //     translate: 'NAV.MAINTAINPAYMENTS.TITLE',
            //     type     : 'item',
            //     url      : '/maintain-payment',
            //     icon     : 'supervised_user_circle'
               
            // },
            // {
            //     id       : 'search-transaction',
            //     title    : 'Search Transactions',
            //     translate: 'NAV.SEARCH.TITLE',
            //     type     : 'item',
            //     icon     : 'search',
            //     url      : '/sample'
            // },
            // {
            //     id       : 'drafts',
            //     title    : 'Drafts',
            //     translate: 'NAV.DRAFTS.TITLE',
            //     type     : 'item',
            //     icon     : 'email',
            //     url      : '/drafts/detail'
            // },            
            {
                id       : 'billing',
                title    : 'Billing & Reports',
                translate: 'NAV.BILLING.TITLE',
                type     : 'item',
                icon     : 'money',
                url      : '/appgateway/billing-reports'
            },
            // {
            //     id       : 'services',
            //     title    : 'Services',
            //     translate: 'NAV.SERVICES.TITLE',
            //     type     : 'collapsable',
            //     icon     : 'supervised_user_circle',
            //     children : [
            //     {
            //         id       : 'concierge',
            //         title    : 'Concierge',
            //         translate: 'NAV.CONCIERGE.TITLE',
            //         type     : 'item',
            //         icon     : 'room_service',
            //         url      : '/services/concierge'
            //     },
            //     {
            //         id       : 'courtesy-copy',
            //         title    : 'Courtesy Copy',
            //         translate: 'NAV.COPY.TITLE',
            //         type     : 'item',
            //         icon     : 'file_copy',
            //         url      : '/services/courtesy-copy'
            //     },
            //     {
            //         id       : 'process-serve',
            //         title    : 'Process Serve',
            //         translate: 'NAV.PROCESS.TITLE',
            //         type     : 'item',
            //         icon     : 'directions_run',
            //         url      : '/services/process-serve'
            //     }
            //     ]
            // },
            {
                id       : 'faq',
                title    : 'FAQ',
                translate: 'NAV.FAQ.TITLE',
                type     : 'item',
                icon     : 'help',
                url      : '/appgateway/faq'
            },
            {
            id       : 'enterprise',
            title    : 'Enterprise Suite',
            translate: 'NAV.ENTERPRISE.TITLE',
            type     : 'group',
            children : [                
                    // {
                    //     id       : 'mail',
                    //     title    : 'Mail',
                    //     translate: 'NAV.MAIL.TITLE',
                    //     type     : 'item',
                    //     icon     : 'email',
                    //     url      : '/appgateway/mail',
                    //     /* openInNewTab : true, */
                    //     externalUrl : true
                    // },            
                    // {
                    //     id       : 'calendar',
                    //     title    : 'Calendar',
                    //     translate: 'NAV.CALENDAR.TITLE',
                    //     type     : 'item',
                    //     icon     : 'today',                
                    //     url      : '/appgateway/calendar',
                    //     /* openInNewTab : true, */
                    //     externalUrl : true
                    // },
                    {
                        id       : 'aln-products',
                        title    : 'Products',
                        translate: 'NAV.PRODUCTS.TITLE',
                        type     : 'collapsable',                               
                        icon     : 'apps',                     
                        children: [
                                {
                                    id       : 'forms-workflow',
                                    title    : 'Forms Workflow',
                                    translate: 'NAV.FORMSWF.TITLE',
                                    type     : 'item',
                                    url      : 'http://formsworkflow.com',
                                    openInNewTab: true,
                                    externalUrl: true
                                },
                                {
                                    id       : 'smart-dockets',
                                    title    : 'Smart Dockets',
                                    translate: 'NAV.SMARTDOCK.TITLE',
                                    type     : 'item',
                                    url      : 'http://smartdockets.com',
                                    openInNewTab: true,
                                    externalUrl: true
                                },
                                {
                                    id       : 'docket-alerts',
                                    title    : 'Docket Alerts',
                                    translate: 'NAV.DOCKETALERTS.TITLE',
                                    type     : 'item',
                                    url      : 'http://docketalerts.com',
                                    openInNewTab: true,
                                    externalUrl: true
                                }
                            ]
                    }
                ]
            },

            {
                id       : 'aln-productmanagement',
                title    : 'Product Management',
                translate: 'NAV.PRODUCTMANAGEMENT.TITLE',
                type     : 'group',
                children: [
                    {
                        id       : 'aln-Admin',
                        title    : 'Administration',
                        translate: 'NAV.ADMIN.TITLE',
                        type     : 'collapsable',                               
                        icon     : 'supervised_user_circle',   
                        hidden   : true,                  
                        children: [
                            {
                                id       : 'administration',
                                title    : 'Manage Firm Users',
                                translate: 'NAV.ADMIN.TITLE',
                                type     : 'item',
                                // icon     : 'supervised_user_circle',
                                url      : '/appgateway/user-management',
                                hidden   : true
                            }
                            ,
                            {
                                id       : 'attorneys',
                                title    : 'Manage Firm Attorneys',
                                translate: 'NAV.ADMIN.TITLE',
                                type     : 'item',
                               // icon     : 'money',
                                url      : '/appgateway/firm-management'
                            },  
                            {
                                id       : 'billing',
                                title    : 'Billing Administration',
                                translate: 'NAV.BILLING.TITLE',
                                type     : 'item',
                                // icon     : 'monetization_on',
                                url      : '/appgateway/billing-management',
                                hidden   : true
                            },
                            {
                                id       : 'settings',
                                title    : 'Settings',
                                translate: 'NAV.SETTINGS.TITLE',
                                type     : 'item',
                                // icon     : 'monetization_on',
                                url      : '/appgateway/settings',
                                hidden   : true
                            },
                            {
                                id       : 'order',
                                title    : 'Update Order',
                                translate: 'NAV.ORDER.TITLE',
                                type     : 'item',
                                //icon     : 'file_copy',
                                url      : '/appgateway/order-update',
                                hidden   : true
                            },
                            {
                                id       : 'firmPayment',
                                title    : 'Manage Firm Payment',
                                translate: 'NAV.MANAGEFIRMPAYMENT.TITLE',
                                type     : 'item',
                                url      : '/appgateway/manage-firm-payment',
                                hidden   : true
                            }
                        ]
                    },
                    {
                        id       : 'profile',
                        title    : 'Profile',
                        translate: 'NAV.PROFILE.TITLE',
                        type     : 'item',
                        icon     : 'person_outline',
                        url      : '/appgateway/profile',
                    }                   
                ]
            }
        ]
    }
];

