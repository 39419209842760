import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment as env } from '@env/environment';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';
import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { AuthModule, ConfigResult, OidcConfigService, OidcSecurityService, OpenIdConfiguration, TokenHelperService } from 'angular-auth-oidc-client';
import { InMemoryWebApiModule } from 'angular-in-memory-web-api';
import { AppComponent } from 'app/app.component';
import { FakeDbService } from 'app/fake-db/fake-db.service';
import { fuseConfig } from 'app/fuse-config';
import { LayoutModule } from 'app/layout/layout.module';
import { SampleModule } from 'app/main/sample/sample.module';
import { GlobalServiceDTO } from 'app/modules/filing-shared/global-shareval';
import 'hammerjs';
import { AppRoutingModule } from './app-routing.module';
import { AppGatewayComponent } from './core/appgateway.component';
import { ALNIdentityAuthService } from './core/auth/aln-identity-auth.service';
import { SubscriptionModalComponent } from './core/auth/subscription-modal/subscription-modal.component';
import { TokenInterceptor } from './core/intercepters/token.interceptor';
import { CentralAPIService } from './core/services/central-api.service';
import { EfilingService } from './core/services/efiling.service';
import { NavService } from './core/services/nav.service';
import { WebApiService } from './core/services/web-api.service';
import { SharedModule } from './shared/shared.module';

export function loadConfig(oidcConfigService: OidcConfigService) {
  return () => oidcConfigService.load_using_stsServer(env.stsUrl);
}

@NgModule({
    declarations: [
        AppComponent,
        AppGatewayComponent
    ],
    imports     : [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,

        TranslateModule.forRoot(),
        InMemoryWebApiModule.forRoot(FakeDbService, {
          delay             : 0,
          passThruUnknownUrl: true
      }),

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        FlexLayoutModule,

        // App modules
        LayoutModule,
        SampleModule,

        SharedModule,
        AppRoutingModule,
        AuthModule.forRoot(),
    ],
    providers: [EfilingService, WebApiService, NavService, CentralAPIService, GlobalServiceDTO, OidcConfigService, ALNIdentityAuthService,
      TokenHelperService, 
      {
          provide: APP_INITIALIZER,
          useFactory: loadConfig,
          deps: [OidcConfigService],
          multi: true,
      },

      {
          provide: HTTP_INTERCEPTORS,
          useClass: TokenInterceptor,
          multi: true
      },

      // {
      //   provide: HTTP_INTERCEPTORS,
      //   useClass: IdentityInterceptor,
      //   multi: true
      // },
      // {
      //   provide: HTTP_INTERCEPTORS,
      //   useClass: CacheControlInterceptor,
      //   multi: true
      // }
    ],
    entryComponents:[
      SubscriptionModalComponent
    ],
    bootstrap   : [
        AppComponent
    ]
})
export class AppModule {
    constructor(private oidcSecurityService: OidcSecurityService, private oidcConfigService: OidcConfigService) {
        this.oidcConfigService.onConfigurationLoaded.subscribe((configResult: ConfigResult) => {
            const config: OpenIdConfiguration = {
                stsServer: configResult.customConfig.stsServer,
                redirect_url: `${env.clientUrl}${env.redirectRoute}`,
                post_login_route: env.redirectRoute,
                post_logout_redirect_uri: env.clientUrl,
                client_id: env.clientId,
                scope: 'openid profile email efapi:access:full',
                response_type: 'code',
                silent_renew: true,
                silent_renew_url: `${env.clientUrl}/${env.silentRenewRoute}`,
                log_console_debug_active: !!env.logConsoleDebugActive,
                disable_iat_offset_validation: true,
                auto_userinfo: true,
            };

            this.oidcSecurityService.setupModule(config, configResult.authWellknownEndpoints);
        });
    }
}
