import { Injectable } from '@angular/core';
import { OidcSecurityService, TokenHelperService } from 'angular-auth-oidc-client';
import { User } from 'app/core/models/user';
import { ALNIdentityAuthUser } from './aln-identity-user';
import { environment as env } from '../../../environments/environment';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ALNIdentityAuthService {
    private authorizedSource = new Subject<boolean>();
    private _isAuthorized: boolean = false;
    private _user: User = new User();

    authorized$ = this.authorizedSource.asObservable();

    get isAuthorized(): boolean {
        return this._isAuthorized;
    }

    get user(): User {
        if (!this.isAuthorized) {
            this._user = new User();
        }

        return this._user;
    }

    constructor(
        public oidcSecurityService: OidcSecurityService,
        private _tokenHelperService: TokenHelperService) {
        this.oidcSecurityService.getIsAuthorized().subscribe(
            (isAuthorized: boolean) => {
                this._isAuthorized = isAuthorized;

                if (isAuthorized) {
                    let token = this.oidcSecurityService.getToken();
                    console.log(token);
                    let userData = this._tokenHelperService.getPayloadFromToken(token, false);
                    this.mapUser(userData);
                } else {
                    this._user = new User();
                }

                this.authorizedSource.next(isAuthorized);
            });
        if (this.oidcSecurityService.moduleSetup) {
            this.doCallbackLogicIfRequired();
        } else {
            this.oidcSecurityService.onModuleSetup.subscribe(() => {
                this.doCallbackLogicIfRequired();
            });
        }


    }

    login() {
        this.oidcSecurityService.authorize();
    }

    logout() {
        this.oidcSecurityService.logoff();
    }

    private doCallbackLogicIfRequired() {
        this.oidcSecurityService.authorizedCallbackWithCode(window.location.toString());
    }

    private mapUser(userData: any) {
        const parseObj = (values) => Array.isArray(values)
            ? values.map(v => JSON.parse(v))
            : [JSON.parse(values)];

        const userInfo = new ALNIdentityAuthUser(userData);
        this._user.userId = userInfo.userId;
        this._user.userName = userInfo.preferred_username;
        this._user.fullName = userInfo.name;
        this._user.profileImageURL = userInfo.profilePictureUrl.startsWith("https://")
            ? userInfo.profilePictureUrl
            : `${env.stsUrl}${userInfo.profilePictureUrl}`;
        this._user.bearerToken = this.oidcSecurityService.getToken();

        this._user.organisation = userInfo.organizationName;
        this._user.applicationRoles = Array.isArray(userInfo.userRoles)
            ? userInfo.userRoles
            : [userInfo.userRoles];

        this._user.clients = parseObj(userInfo.products);
        this._user.allClients = parseObj(userInfo.allProducts);
        localStorage.setItem('currentUser', JSON.stringify(this._user));
    }
}