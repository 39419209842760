import { navigation } from 'app/navigation/navigation';

import { BehaviorSubject, Observable, of, forkJoin, Subject } from 'rxjs';
import { User } from '../models/user';
import { map, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { FuseNavigation } from '@fuse/types/fuse-navigation';
import { GenericActionResult } from '../models';
import { WebApiService } from './web-api.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { EFilingApplicationSetting } from '../models/EFilingApplicationSetting';

const BASE_URL_WEBAPI = environment.webApiBaseUri;

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    public currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;

    public settingsSubject: BehaviorSubject<EFilingApplicationSetting[]>;
    public settings: Observable<EFilingApplicationSetting[]>;

    constructor(
        private http: HttpClient,
        private router: Router,
        private authentication: AuthenticationService,
        private fuseNavigation: FuseNavigationService,
    ) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();

        this.settingsSubject = new BehaviorSubject<EFilingApplicationSetting[]>(JSON.parse(localStorage.getItem('settings')));
        this.settings = this.settingsSubject.asObservable();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    public get eFilingSettings(): EFilingApplicationSetting[] {
        return this.settingsSubject.value;
    }

    login(username, password) {
        return this.http.post<any>(`${BASE_URL_WEBAPI}/users`, { username, password })
            .pipe(map(response => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                this.RegisterCurrentUserSetup(response);
                //redirect an Admin to this page
                if (this.isAdmin('SecurityAdmin')) {
                    this.router.navigate(['/user-management']);
                }
                return response;
            }));
    }

    loginFromOc(ocToLascId: string): Observable<boolean> {
        return this.http.post<GenericActionResult>(`${BASE_URL_WEBAPI}/users/orange-county-session-mapping`, { value: ocToLascId })
            .pipe(map(response => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                this.RegisterCurrentUserSetup(response);
                return response.isSuccess;
            }));
    }

    loginFromEFilingCentral(token: string): Observable<boolean> {
        var result = new Subject<boolean>();
        this.http.post<GenericActionResult>(`${environment.eFilingCentralApiBaseUri}/users/central-login`, { value: token })
            .subscribe(response => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                this.RegisterCurrentUserSetup(response);
                result.next(true);
            });
        return result.asObservable();
    }

    private RegisterCurrentUserSetup(response: any) {
        localStorage.setItem('currentUser', JSON.stringify(response.data));
        this.currentUserSubject.next(response.data);
        this.currentUserSubject.subscribe(u => u.profileImageURL = "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png");
        this.setupMenu();
    }

    setupMenu() {
        // Get default navigation
        const menu = this.getUserNavigation(navigation);

        this.fuseNavigation.unregister('main');
        this.fuseNavigation.register('main', menu);
        this.fuseNavigation.setCurrentNavigation('main');
    }

    getUserNavigation(items: FuseNavigation[]) {
        if (this.isAdmin('SecurityAdmin')) {
            items[0]
                .children.find(item => item.id === 'aln-productmanagement')
                .children.find(child => child.id === 'aln-Admin')
                .children.find(grandChild => grandChild.id === 'administration')
                .hidden = false;
            items[0]
                .children.find(item => item.id === 'aln-productmanagement')
                .children.find(child => child.id === 'aln-Admin')
                .hidden = false;
            items[0]
                .children.find(item => item.id === 'aln-productmanagement')
                .children.find(child => child.id === 'aln-Admin')
                .children.find(grandChild => grandChild.id === 'settings')
                .hidden = false;

            items[0]
                .children.find(item => item.id === 'aln-productmanagement')
                .children.find(child => child.id === 'aln-Admin')
                .children.find(grandChild => grandChild.id === 'order')
                .hidden = false;

            items[0]
                .children.find(item => item.id === 'aln-productmanagement')
                .children.find(child => child.id === 'aln-Admin')
                .children.find(grandChild => grandChild.id === 'firmPayment')
                .hidden = false;
        }

        return items.slice();
    }

    isAdmin(adminType: string = 'SecurityAdmin'): boolean {
        if (this.currentUserSubject.value) {
            const applicationRoles = this.currentUserSubject.value.applicationRoles;
            if (applicationRoles) {
                const roles = applicationRoles.find(role => role.rolecode === adminType);
                return !!roles;
            }
        }
        return false;
    }

    logout() {
        // remove user from local storage and set current user to null
        localStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
        this.router.navigate(['/auth/login']);
    }

    getSettingsFrmAPI(): Observable<GenericActionResult> {
        return this.http.get<GenericActionResult>(`${BASE_URL_WEBAPI}/application-setting/efiling-application-setting-list`)
            .pipe(tap(response => {
                localStorage.setItem('settings', JSON.stringify(response.data));
                this.settingsSubject.next(response.data);
            }));
    }

}
